import React, {useState, useCallback, useEffect} from 'react';
import {Button, Grid, Alert, Chip, Card, CardMedia, Skeleton, Box, Checkbox, FormControlLabel, useMediaQuery, Typography, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {get, isUndefined} from 'lodash';
import {useForm, FormProvider} from 'react-hook-form';
import {LoadingButton} from '@mui/lab';
import {getFirestore, doc, updateDoc} from 'firebase/firestore';
import {useSnackbar} from 'notistack';
import {useTheme} from '@mui/material/styles';
import {getAuth} from 'firebase/auth';
import axios from 'axios';
import moment from 'moment';
import {getFunctions, httpsCallable} from 'firebase/functions';

import firebaseApp from '../firebase';
import {Ranks, SkillStages, DriverOperatorSkills, getSkillLabel, uploadSkillFile} from '../data/utils';

import ToggleButtonField from '../form/ToggleButtonField';
import TextField from '../form/TextField';
import SelectField from '../form/SelectField';
import DatePickerField from '../form/DatePickerField';

const compactObject = obj => {
    return JSON.parse(JSON.stringify(obj, (key, value) => {
        return (value === null ? undefined : value);
    }));
};

const MemberTraining = props => {
    const {open, onClose, field, isLevel, editable = false, member = {}} = props;
    const {uid, email, fullName, role} = member;
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [syncing, setSyncing] = useState(false);
    const [loadingFile, setLoadingFile] = useState(false);
    const [iframeSrc, setIframeSrc] = useState(null);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const db = getFirestore(firebaseApp);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const functions = getFunctions(firebaseApp);
    const deleteSkillFile = httpsCallable(functions, 'deleteSkillFile');
    const isDriverOperator = field && ['exterior.apparatusDriverOperator'].includes(field.replace('skills.', ''));

    const rawValue = get(member, field, 'INCOMPLETE');
    const value = typeof rawValue === 'string' ? {status: rawValue} : rawValue || {};

    const {
        status: rawStatus,
        driveFileId: rawDriveFileId,
        uploadedDate: rawUploadedDate,
        scheduledDate: rawScheduledDate,
        completedDate: rawCompletedDate,
        issueDate: rawIssueDate,
        expiryDate: rawExpiryDate,
        licenceType = '',
        licenceNumber,
        redcrossEmail
    } = value;

    const defaultValues = {
        status: rawStatus,
        driveFileId: rawDriveFileId,
        uploadedDate: rawUploadedDate && rawUploadedDate.toDate ? rawUploadedDate.toDate() : rawUploadedDate,
        scheduledDate: rawScheduledDate && rawScheduledDate.toDate ? rawScheduledDate.toDate() : rawScheduledDate,
        completedDate: rawCompletedDate && rawCompletedDate.toDate ? rawCompletedDate.toDate() : rawCompletedDate,
        issueDate: rawIssueDate && rawIssueDate.toDate ? rawIssueDate.toDate() : rawIssueDate,
        expiryDate: rawExpiryDate && rawExpiryDate.toDate ? rawExpiryDate.toDate() : rawExpiryDate,
        licenceType,
        licenceNumber,
        redcrossEmail
    };

    if (isDriverOperator) {
        for (const driverSkill of Object.keys(DriverOperatorSkills)) {
            const key = `driverOperatorSkill${driverSkill}`;
            const {completedDate: rawCompletedDate} = get(value, key, {});
            const completedDate = rawCompletedDate && rawCompletedDate.toDate ? rawCompletedDate.toDate() : rawCompletedDate;
            
            defaultValues[key] = {completedDate}
        }
    }

    let hasHigherLevel = false;
    if (isLevel) {
        if (defaultValues.completedDate && !rawStatus) {
            defaultValues.status = 'COMPLETE';
        } else {
            defaultValues.status = rawStatus || 'INCOMPLETE';
        }

        if ((field === 'skills.exterior' || field === 'skills.interior')) {
            const hasInterior = !!get(member, 'skills.interior.completedDate');
            const hasFullService = !!get(member, 'skills.fullService.completedDate');

            if (hasFullService) {
                hasHigherLevel = 'This member has also completed Full Service';
            } else if (hasInterior && field !== 'skills.interior') {
                hasHigherLevel = 'This member has also completed Interior';
            }
        }
    }

    const methods = useForm({
        defaultValues,
        mode: 'onChange'
    });
    const {handleSubmit, formState, watch, setValue} = methods;
    const {isValid} = formState;

    const status = watch('status');
    const driveFileId = watch('driveFileId');
    const uploadedDate = watch('uploadedDate');
    const hasExpiryDate = !!watch('expiryDate');
    const showScheduledDate = ['NEEDED', 'SCHEDULED'].includes(status);
    const isCompleted = field && status === 'COMPLETE';
    const isFR = field && status === 'COMPLETE' && ['exterior.fr3'].includes(field.replace('skills.', ''));
    const {levelLabel, skillLabel, hasExpiry} = getSkillLabel(field);

    const driverOperatorSkills = Object.keys(DriverOperatorSkills).map(driverSkill => {
        const key = `driverOperatorSkill${driverSkill}`;
        return watch(`${key}.completedDate`);
    });

    const getFileDetails = useCallback(async(uid, skill) => {
        const token = await getAuth(firebaseApp).currentUser.getIdToken();
        const isDev = process.env.NODE_ENV === 'development';
        const base = isDev ? 'http://127.0.0.1:5001/jrfd-log/us-central1/' : 'https://us-central1-jrfd-log.cloudfunctions.net/';

        try {
            const response = await axios({
                url: `${base}downloadSkillFile`,
                method: 'POST',
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: {
                    uid,
                    skill
                }
            });

            const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
            setIframeSrc(url);

            return url;
        } catch(e) {
            setValue('driveFileId', null);
        }
    }, [setValue]);

    useEffect(() => {
        if (!iframeSrc && driveFileId) {
            const fn = async() => {
                setLoadingFile(true);
                await getFileDetails(uid, field);
                setLoadingFile(false);
            };

            fn();
        }
    }, [status, field, uid, iframeSrc, driveFileId, getFileDetails]);

    const handleFileUpload = useCallback(async file => {
        if (!file || !uid) {
            return;
        }

        setUploading(true);

        try {
            const driveFileId = await uploadSkillFile(uid, field, file);

            if (driveFileId) {
                setValue('driveFileId', driveFileId);
                setValue('uploadedDate', new Date());

                setLoadingFile(true);
                await getFileDetails(uid, field);
                setLoadingFile(false);
            }

            setUploading(false);
            
            enqueueSnackbar('File uploaded successfully!', {variant: 'success'});
        } catch(e) {
            enqueueSnackbar(e, {variant: 'error'});
        }

        setLoading(false);
    }, [uid, enqueueSnackbar, field, setUploading, getFileDetails, setValue]);

    const handleDelete = useCallback(async() => {
        const onDelete = async() => {
            try {
                setDeleting(true);
    
                await deleteSkillFile({
                    uid,
                    skill: field
                });

                setIframeSrc(null);
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }
    
            setDeleting(false);
        };

        enqueueSnackbar('Are you sure you want to delete this file?', {
            variant: 'warning',
            action: key => {
                return (
                    <>
                        <Button onClick={() => {
                            closeSnackbar(key);
                            onDelete();
                        }}>
                            Delete
                        </Button>
                        <Button onClick={() => closeSnackbar(key)}>
                            Cancel
                        </Button>
                    </>
                );
            }
        });
    }, [closeSnackbar, enqueueSnackbar, uid, field, deleteSkillFile, setIframeSrc]);

    const handleSync = useCallback(async() => {
        setSyncing(true);
        const url = await getFileDetails(uid, field);

        if (!url) {
            const path = field.replace('skills.', '').replaceAll('.', '/');
            enqueueSnackbar(`File not found in drive: ${email}/${path}.pdf`, {variant: 'error'});
        } else {
            setValue('uploadedDate', new Date());
        }

        setSyncing(false);
    }, [uid, field, getFileDetails, enqueueSnackbar, email, setValue]);

    const onSubmit = useCallback(async data => {
        setLoading(true);

        const {status, scheduledDate, completedDate, issueDate, expiryDate, licenceType, licenceNumber, redcrossEmail, driveFileId, uploadedDate, ...rest} = data;
        
        try {
            let newData;

            if (isLevel) {
                newData = {};

                if (!isUndefined(completedDate)) {
                    newData[`${field}.completedDate`] = completedDate;
                }

                if (driveFileId) {
                    newData[`${field}.driveFileId`] = driveFileId;
                }

                if (uploadedDate) {
                    newData[`${field}.uploadedDate`] = uploadedDate;
                }

                if (status) {
                    newData[`${field}.status`] = status;
                }
            } else {
                const newFieldData = {
                    status,
                    ...(showScheduledDate && scheduledDate && {scheduledDate}),
                    ...driveFileId && {driveFileId},
                    ...uploadedDate && {uploadedDate},
                    ...(expiryDate && {expiryDate}),
                    ...(isCompleted && {
                        ...(completedDate && {completedDate})
                    }),
                    ...(isFR && {
                        ...(issueDate && {issueDate}),
                        ...(licenceType && {licenceType}),
                        ...(licenceNumber && {licenceNumber}),
                        ...(redcrossEmail && {redcrossEmail})
                    }),
                    ...isDriverOperator && rest && compactObject(rest)
                };

                newData = {
                    [field]: newFieldData
                };
            }

            if (!newData) {
                return;
            }

            const ref = doc(db, 'users', uid);
            await updateDoc(ref, newData);

            onClose();
        } catch (e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }
    }, [onClose, db, enqueueSnackbar, field, showScheduledDate, isFR, isCompleted, uid, isLevel, isDriverOperator]);

    const showExpiryDate = hasExpiryDate || hasExpiry;

    return (
        <FormProvider {...methods}>
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth="lg"
                sx={{mt: 8}}
                fullWidth
                disableEscapeKeyDown={loading}
            >
                <DialogTitle sx={{display: 'flex', alignItems: 'stretch', flexDirection: 'column'}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            {fullName}
                            <Typography variant="subtitle1">{Ranks[role]}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Chip label={isLevel ? levelLabel : skillLabel} />
                        </Grid>
                    </Grid>

                    {hasHigherLevel && (
                        <Alert severity="success" sx={{flex: 1, mt: 2}}>
                            {hasHigherLevel}
                        </Alert>   
                    )}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        {!isDriverOperator && (
                            <Grid item xs={12} sx={{display: 'flex'}}>
                                <ToggleButtonField
                                    name="status"
                                    disabled={!editable}
                                    label="Status"
                                    size={isSmall ? 'small' : undefined}
                                    exclusive
                                    orientation={isSmall ? 'vertical' : 'horizontal'}
                                    sx={{flex: 1}}
                                    options={Object.keys(SkillStages).map(value => {
                                        const {label, color, icon} = SkillStages[value];
                                        return {
                                            value,
                                            label: (
                                                <>
                                                    {icon}
                                                    <Box sx={{ml: 1}}>
                                                        {label}
                                                    </Box>
                                                </>
                                            ),
                                            color
                                        };
                                    })}
                                />    
                            </Grid>
                        )}

                        {showScheduledDate && (
                            <Grid item sx={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                <DatePickerField
                                    views={status === 'NEEDED' ? ['year'] : ['year', 'month']}
                                    inputFormat={status === 'NEEDED' ? 'yyyy' : 'MMMM yyyy'}
                                    label={status === 'NEEDED' ? 'Target Date' : 'Scheduled Date'}
                                    name="scheduledDate"
                                    disabled={!editable}
                                />
                            </Grid>
                        )}

                        {isDriverOperator && (
                            <Grid item xs={12} sx={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                {Object.keys(DriverOperatorSkills).map((driverSkill, index) => {
                                    const key = `driverOperatorSkill${driverSkill}`;

                                    return (
                                        <Box key={key} sx={{flex: 1, display: 'flex', flexDirection: 'row', mb: 1}}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={!!driverOperatorSkills[index]}
                                                        disabled={!editable}
                                                        onChange={() => setValue(`${key}.completedDate`, driverOperatorSkills[index] ? null : new Date())}
                                                    />
                                                }
                                                label={`#${driverSkill}: ${DriverOperatorSkills[driverSkill]}`}
                                                sx={{flex: 1}}
                                            />

                                            <DatePickerField
                                                size="small"
                                                views={['year', 'month']}
                                                inputFormat="MMMM yyyy"
                                                label="Completed Date"
                                                name={`${key}.completedDate`}
                                                disabled={!editable}
                                            />
                                        </Box>
                                    );
                                })}
                            </Grid>
                        )}

                        {(isCompleted || isDriverOperator) && (
                            <Grid item sx={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                {!isFR && !isDriverOperator && (
                                    <Grid container spacing={2}>
                                        <Grid item xs={showExpiryDate ? 6 : 12} sx={{display: 'flex', flexDirection: 'column'}}>
                                            <DatePickerField
                                                margin="normal"
                                                views={['year', 'month']}
                                                inputFormat="MMMM yyyy"
                                                label="Completed Date"
                                                name="completedDate"
                                                disabled={!editable}
                                            />
                                        </Grid>

                                        {showExpiryDate && (
                                            <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                                                <DatePickerField
                                                    margin="normal"
                                                    label="Expiry Date"
                                                    name="expiryDate"
                                                disabled={!editable}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                )}

                                {isFR && (
                                    <Grid container spacing={1}>
                                        <Grid item xs={4} sx={{display: 'flex', flexDirection: 'column'}}>
                                            <DatePickerField
                                                margin="normal"
                                                label="Issue Date"
                                                name="issueDate"
                                                disabled={!editable}
                                            />
                                            <TextField
                                                margin="normal"
                                                label="Licence #"
                                                name="licenceNumber"
                                                disabled={!editable}
                                            />
                                        </Grid>
                                        <Grid item xs={4} sx={{display: 'flex', flexDirection: 'column'}}>
                                            <DatePickerField
                                                margin="normal"
                                                label="Expiry Date"
                                                name="expiryDate"
                                                disabled={!editable}
                                            />
                                            <TextField
                                                margin="normal"
                                                label="Red Cross Email"
                                                name="redcrossEmail"
                                                disabled={!editable}
                                            />
                                        </Grid>
                                        <Grid item xs={4} sx={{display: 'flex', flexDirection: 'column'}}>
                                            <SelectField
                                                sx={{mt: 2}}
                                                margin="normal"
                                                label="Licence Type"
                                                name="licenceType"
                                                disabled={loading || !editable}
                                                options={[
                                                    {value: '', label: 'Select'},
                                                    {value: 'EMAFR', label: 'FR'},
                                                    {value: 'EMAFRWSCOPE', label: 'FR (without scope update)'},
                                                    {value: 'EMR', label: 'EMR'},
                                                    {value: 'EMRWSCOPE', label: 'EMR (without scope update)'},
                                                    {value: 'PCP', label: 'PCP'},
                                                    {value: 'PCPWSCOPE', label: 'PCP (without scope update)'}
                                                ]}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                                        
                                {(iframeSrc || loadingFile || driveFileId) && (
                                    <Card variant="outlined" sx={{mt: 2}}>
                                        {loadingFile ? (
                                            <Skeleton sx={{height: 400}} animation="wave" variant="rectangular" />
                                        ) : (
                                            <CardMedia component="iframe" src={iframeSrc} sx={{height: 400, border: 0}} />
                                        )}
                                    </Card>
                                )}

                                {editable && (
                                    <Box sx={{display: 'flex', alignItems: 'center', mt: 2}}>
                                        <LoadingButton loading={loadingFile || uploading} variant="contained" component="label">
                                            Upload File
                                            <input
                                                type="file"
                                                hidden
                                                accept="application/pdf"
                                                onChange={e => {
                                                    const file = e.target.files[0];
                                                    handleFileUpload(file);
                                                }}
                                            />
                                        </LoadingButton>

                                        {(!loadingFile && driveFileId && !uploading) && (
                                            <LoadingButton onClick={handleDelete} sx={{ml: 1}} loading={deleting} variant="outlined" component="label">
                                                Delete File
                                            </LoadingButton>
                                        )}

                                        {(!driveFileId && !iframeSrc && !uploading) && (
                                            <LoadingButton onClick={handleSync} loading={loadingFile || uploading || syncing} variant="outlined" component="label" sx={{ml: 1}}>
                                                Sync from Drive
                                            </LoadingButton>
                                        )}
                                        {(driveFileId && !uploading) && <Typography variant="caption" sx={{flex: 1, textAlign: 'right'}}>Last update: {uploadedDate ? moment(uploadedDate).format('LLL') : 'never'}</Typography>}
                                    </Box>
                                )}
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>{editable ? 'Cancel' : 'Close'}</Button>
                    {editable && <LoadingButton loading={loading} disabled={!isValid} onClick={handleSubmit(onSubmit)}>Save</LoadingButton>}
                </DialogActions>
            </Dialog>
        </FormProvider>
    );
}
export default MemberTraining;