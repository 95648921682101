import React from 'react';
import {Controller} from 'react-hook-form';
import {FormControlLabel, Checkbox} from '@mui/material';

export default function ControlledCheckboxField(props) {
    const {name, label, rules, margin, sx, fullWidth, labelPlacement = 'start', ...rest} = props;

    return (
        <Controller
            name={name}
            rules={rules}
            render={({field}) => {
                const {value, ...restField} = field;

                return (
                    <FormControlLabel
                        margin={margin}
                        sx={sx}
                        labelPlacement={labelPlacement}
                        control={
                            <Checkbox
                                checked={value || false}
                                {...restField}
                                {...rest}
                            />
                        }
                        label={label}
                    />
                );
            }}
        />
    );
};
