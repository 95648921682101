import React, {useState, useEffect, useMemo, useCallback, useContext} from 'react';
import {Card, CardContent, Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField} from '@mui/material';
import {useSnackbar} from 'notistack';
import {getFirestore, collection, getDocs, query, where} from 'firebase/firestore';
import moment from 'moment';
import {useForm, useFormContext, FormProvider} from 'react-hook-form';
import {LoadingButton} from '@mui/lab';
import {useNavigate} from 'react-router-dom';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {getAuth} from 'firebase/auth';
import axios from 'axios';

import {useGridApiRef} from '@mui/x-data-grid-pro';

import firebaseApp from '../firebase';
import {hasFeature} from '../features';

import {verifyOfficer, populateCollectionIds} from '../data/utils';

import {UserContext} from '../contexts/User';

import DatePickerField from '../form/DatePickerField.js';
import SelectField from '../form/SelectField.js';

import IncidentsGrid from './incidents/Grid';
import Stats from './incidents/Stats';

const ImportFromIAR = () => {
    const [importing, setImporting] = useState(false);
    const [open, setOpen] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const navigate = useNavigate();
    const functions = getFunctions(firebaseApp);
    const {enqueueSnackbar} = useSnackbar();
    const importIncidentsFromIAR = httpsCallable(functions, 'importIncidentsFromIAR');

    const handleClose = () => {
        setOpen(false);
    };

    const handleImport = useCallback(async() => {
        setImporting(true);

        try {
            const {data} = await importIncidentsFromIAR({startDate});
            const {incidents = []} = data;

            navigate('/incidents/import', {state: {
                incidents: incidents.map(incident => {
                    const{date, ...rest} = incident;

                    return {
                        ...rest,
                        date: new Date(date)
                    };
                })
            }});
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setImporting(false);
    }, [importIncidentsFromIAR, enqueueSnackbar, navigate, startDate]);

    return (
        <>
            <LoadingButton loading={importing} variant="contained" sx={{ml: 1}} onClick={() => setOpen(true)}>
                Import latest from IAR
            </LoadingButton>

            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: e => {
                        e.preventDefault();

                        handleImport();
                    }
                }}
            >
                <DialogTitle>Import from IamResponding</DialogTitle>
                <DialogContent>
                    <DatePicker
                        label={"Start Date"}
                        inputFormat="DD/MM/yyyy"
                        value={startDate}
                        onChange={date => setStartDate(date && date.toDate())}
                        renderInput={params => (
                            <TextField
                                fullWidth 
                                name="startDate"
                                margin="dense"
                                {...params}
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={importing} onClick={handleClose}>Cancel</Button>
                    <LoadingButton loading={importing} type="submit">Start Import</LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

const Filter = ({loading, onSubmit}) => {
    const {handleSubmit, watch, reset} = useFormContext();
    const dateType = watch('dateType');

    useEffect(() => {
        if (dateType === 'thisYear') {
            const startDate = moment().startOf('year').toDate();
            const endDate = moment().endOf('year').toDate();

            reset({dateType, startDate, endDate});
        } else if (dateType === 'lastYear') {
            const startDate = moment().subtract(1, 'year').startOf('year').toDate();
            const endDate = moment().subtract(1, 'year').endOf('year').toDate();

            reset({dateType, startDate, endDate});
        } else if (dateType === 'thisQuarter') {
            const startDate = moment().startOf('quarter').toDate();
            const endDate = moment().endOf('quarter').toDate();

            reset({dateType, startDate, endDate});
        } else if (dateType === 'lastQuarter') {
            const startDate = moment().startOf('quarter').subtract(1, 'quarter').toDate();
            const endDate = moment().endOf('quarter').subtract(1, 'quarter').toDate();

            reset({dateType, startDate, endDate});
        } else if (dateType === 'thisMonth') {
            const startDate = moment().startOf('month').toDate();
            const endDate = moment().endOf('month').toDate();

            reset({dateType, startDate, endDate});
        }
    }, [dateType, reset]);

    return (
        <Card sx={{mb: 2}}>
            <CardContent sx={{p: 2}}>
                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end', pt: 1}} component="form" onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={{flex: 1}}>
                        <SelectField
                            sx={{mr: 1, width: 250}}
                            label="Search Queries"
                            name="dateType"
                            disabled={loading}
                            options={[
                                {label: 'This Year', value: 'thisYear'},
                                {label: 'This Quarter', value: 'thisQuarter'},
                                {label: 'This Month', value: 'thisMonth'},
                                {label: 'Last Year', value: 'lastYear'},
                                {label: 'Last Quarter', value: 'lastQuarter'}
                            ]}
                        />

                        <DatePickerField
                            sx={{mr: 1, width: 250}}
                            label="Start Date"
                            name="startDate"
                            disabled={loading}
                        />
                        <DatePickerField
                            sx={{mr: 1, width: 250}}
                            label="End Date"
                            name="endDate"
                            disabled={loading}
                        />
                    </Box>

                    <LoadingButton
                        type="submit"
                        variant="contained"
                        onClick={handleSubmit(onSubmit)}
                        disabled={loading}
                        loading={loading}
                    >
                        Search
                    </LoadingButton>
                </Box>
            </CardContent>
        </Card>
    );
};

const Incidents = () => {
    const [loading, setLoading] = useState(false);
    const [exporting, setExporting] = useState(false);
    const [incidents, setIncidents] = useState([]);
    const [users, setUsers] = useState([]);
    const db = getFirestore(firebaseApp);
    const {enqueueSnackbar} = useSnackbar();
    const apiRef = useGridApiRef();
    const {currentUser} = useContext(UserContext);
    const {isAdmin} = currentUser;
    const isOfficer = verifyOfficer(currentUser);

    const defaultValues = useMemo(() => ({
        dateType: 'thisYear',
        startDate: moment().startOf('year').toDate(),
        endDate: moment().toDate()
    }));

    const methods = useForm({
        defaultValues,
        mode: 'onChange'
    });
    const {watch} = methods;
    const startDate = watch('startDate');
    const endDate = watch('endDate');

    const fetchUsers = useCallback(async() => {
        const usersRef = collection(db, 'users');
        const rawUsers = await getDocs(query(usersRef));
        let users = [];

        rawUsers.forEach(user => {
            const {id} = user;
            const {role, ...rest} = user.data();

            if (role !== 'STATION') {
                users.push({
                    id,
                    uid: id,
                    role,
                    ...rest
                });
            }
        });

        return users;
    }, [db]);

    const onSubmit = useCallback(async data => {
        let {startDate, endDate} = data;
        if (!startDate || !endDate) {
            return;
        }

        async function fetch() {
            setLoading(true);

            try {
                startDate = moment(startDate).startOf('day').toDate();
                endDate = moment(endDate).endOf('day').toDate();

                const ref = collection(db, 'incidents');
                const q = query(ref, where('date', '>=', startDate), where('date', '<=', endDate));
                const raw = await getDocs(q);
                let incidents = [];

                raw.forEach(doc => {
                    const data = doc.data();

                    incidents.push({
                        id: doc.id,
                        uid: doc.id,
                        ...data
                    });
                });

                const apparatusIds = incidents.reduce((result, doc) => {
                    const {vehicles} = doc;
                    if (!vehicles) {
                        return result;
                    }

                    return [
                        ...result,
                        ...vehicles
                    ];
                }, []);

                const apparatus = await populateCollectionIds(db, 'apparatus', apparatusIds);
                const allUsers = await fetchUsers();

                incidents = incidents.map(doc => {
                    const {date, members, vehicles, incidentTypeDescription, ...rest} = doc;

                    return {
                        ...rest,
                        date: date.toDate(),
                        vehicles: vehicles.map(id => {
                            return apparatus[id];
                        }),
                        members: members.map(id => {
                            return allUsers.find(member => member.id === id);
                        })
                    }
                });

                setUsers(allUsers);
                setIncidents(incidents);
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }

            setLoading(false);
        }

        fetch();
    }, [enqueueSnackbar, db, fetchUsers]);

    useEffect(() => {
        onSubmit(defaultValues);
    }, []);

    const onRowClick = useCallback((params, e) => {
        const {metaKey} = e;
        const {row} = params;

        if (!metaKey) {
            return;
        }
        
        const url = `/incidents/${row.id}`;
        window.open(url);
    }, []);

    const scrollToIncident = useCallback(incident => {
        const {id} = incident;
        
        const el = apiRef.current.getRowElement(id);
        el.scrollIntoViewIfNeeded()

        apiRef.current.setExpandedDetailPanels([id]);
    }, [apiRef]);

    const handleExport = useCallback(async() => {
        setExporting(true);

        const token = await getAuth(firebaseApp).currentUser.getIdToken();
        const isDev = process.env.NODE_ENV === 'development';
        const base = isDev ? 'http://127.0.0.1:5001/jrfd-log/us-central1/' : 'https://us-central1-jrfd-log.cloudfunctions.net/';

        try {
            const response = await axios({
                url: `${base}exportIncidents`,
                method: 'POST',
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: {
                    startDate,
                    endDate
                }
            });

            const start = moment(startDate).format('YYYY-MM-DD');
            const end = moment(endDate).format('YYYY-MM-DD');

            const href = URL.createObjectURL(response.data);

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `Incidents - ${start} to ${end}.xlsx`);
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setExporting(false);
    }, [startDate, endDate, enqueueSnackbar]);

    return (
        <FormProvider {...methods}>
            {isAdmin && hasFeature('importIncidentsFromIAR') && (
                <Box sx={{mb: 2, display: 'flex', justifyContent: 'flex-end'}}>
                    <ImportFromIAR />
                </Box>
            )}

            <Filter loading={loading} onSubmit={onSubmit} />

            <Box sx={{opacity: loading ? .5 : 1}}>
                {(incidents.length > 0) && <Stats incidents={incidents} users={users} scrollToIncident={scrollToIncident} expanded={!isOfficer} />}

                {isOfficer && (
                    <IncidentsGrid
                        apiRef={apiRef}
                        stateId="incidents"
                        incidents={incidents}
                        loading={loading}
                        onRowClick={onRowClick}
                    />
                )}

                {isAdmin && (
                    <Box sx={{mt: 2, display: 'flex', justifyContent: 'flex-end'}}>
                        <LoadingButton disabled={loading || !incidents.length} loading={exporting} variant="contained" sx={{ml: 1}} onClick={handleExport}>
                            Export Incidents
                        </LoadingButton>
                    </Box>
                )}
            </Box>
        </FormProvider>
    );
};

export default Incidents;