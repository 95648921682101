import React from 'react';
import {Box, ToggleButtonGroup, ToggleButton} from '@mui/material';
import {map, uniq} from 'lodash';
import moment from 'moment';
import {BarChart} from '@mui/x-charts';

import BaseChart from '../../../components/stats/BaseChart';

import {IncidentTypes, colorForIncidentType} from '../../../data/utils';

const Attendance = function(props) {
    const {training = [], users} = props;
    const [filter, setFilter] = React.useState('ALL');
    console.log(training)


    const attendance = training.map(training => {
        const {date: rawDate, types, members = []} = training;


        return {
            ...training,
            attendance: members.length
        }
    });

    // const attendance = users.reduce((result, user) => {
    //     const {uid, station, ...rest} = user;

    //     const userIncidents = training.reduce((result, incident) => {
    //         const {members = []} = incident;

    //         if (members.filter(Boolean).find(member => member.uid === uid)) {
    //             result.push(incident);
    //         }

    //         return result;
    //     }, []);

    //     if (filter === 'ALL' || filter === station) {
    //         if (!result[uid] && (userIncidents.length > 0 || user.deactivated !== true)) {
    //             result[uid] = {
    //                 uid,
    //                 ...rest,
    //                 incidents: userIncidents
    //             };
    //         }
    //     }

    //     return result;
    // }, {});

    // const incidentTypes = uniq(training.map(incident => incident.type));

    // const dataset = map(attendance, (member, key) => {
    //     const {fullName, incidents} = member || {};
    //     const incidentTypeCounts = incidentTypes.reduce((result, type) => {
    //         result[type] = incidents.filter(incident => incident.type === type).length;

    //         return result;
    //     }, {});

    //     return {
    //         fullName,
    //         attendance: incidents.length,
    //         ...incidentTypeCounts
    //     };
    // });

    return (
        <Box>
            <ToggleButtonGroup
                fullWidth
                exclusive
                value={filter}
                variant="outlined"
                size="small"
                onChange={(e, value) => {
                    setFilter(value);
                }}
                sx={{mb: 2}}
            >
                <ToggleButton value="ALL">All</ToggleButton>
                <ToggleButton value="51">Station 51</ToggleButton>
                <ToggleButton value="52">Station 52</ToggleButton>
            </ToggleButtonGroup>

            <Box sx={{display: 'flex', flexDirection: 'row', mb: 2}}>
                <BaseChart title="Responses" sx={{flex: 1, mr: 1}}>
                    <BarChart
                        margin={{left: 150, top: 20, right: 30, bottom: 40}}
                        dataset={[...attendance].sort((a, b) => b.date < a.date ? 1 : -1)}
                        yAxis={[{scaleType: 'band', dataKey: 'attendance'}]}
                        xAxis={[{tickMinStep: 5}]}
                        series={[{type: 'bar', dataKey: 'attendance', fill: '#8884d8'}]}
                        slotProps={{legend: {hidden: true}}}
                        layout="horizontal"
                        height={700}
                    />
                </BaseChart>
            </Box>
        </Box>
    );
};

export default Attendance;