import React from 'react';

import TextField from './TextField';

export default function ControlledPasswordField(props) {
    return (
        <TextField
            autoCapitalize="none"
            autoCorrect="off"  
            textcontenttype="password"
            autoComplete="password"  
            {...props}
            securetextentry="true"
        />
    );
};