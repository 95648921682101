import React from 'react';
import {Box} from '@mui/material';
import {GoogleMap, OverlayView, useJsApiLoader} from '@react-google-maps/api';

import BaseChart from '../../../components/stats/BaseChart';
import {colorForIncidentType} from '../../../data/utils';

const Map = function(props) {
    const {incidents, scrollToIncident} = props;

    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyCCI_7230YkA7R8LJXiqrg6_zVlfy9eSfk'
    });

    const center = {
        lat: 49.864768,
        lng: -119.197825
    };

    // const [map, setMap] = useState(null);

    // const onLoad = useCallback((map) => {
    //     // This is just an example of getting and using the map instance!!! don't just blindly copy!
    //     // const bounds = new window.google.maps.LatLngBounds(center);
    //     // map.fitBounds(bounds);

    //     setMap(map)
    // }, []);

    // const onUnmount = useCallback(function callback(map) {
    //     setMap(null);
    // }, []);

    return (
        <BaseChart title="Incident Map" sx={{mb: 2}}>
            {isLoaded && (
                <GoogleMap
                    mapContainerStyle={{width: '100%', height: 500}}
                    center={center}
                    zoom={12}
                >
                    {incidents.map((incident, index) => {
                        const {type, location} = incident;
                        const {latitude, longitude} = location || {};
                        const color = colorForIncidentType(type);

                        const position = {
                            lat: latitude,
                            lng: longitude
                        };

                        return (
                            <OverlayView
                                key={index}
                                position={position}
                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                            >
                                <Box
                                    sx={{backgroundColor: color, width: 15, height: 15, borderRadius: 7}}
                                    onClick={() => scrollToIncident(incident)}
                                />
                            </OverlayView>
                        );
                    })}
                </GoogleMap>
            )}
        </BaseChart>
    );
}

export default Map;