import React from 'react';
import {Controller} from 'react-hook-form';
import {TextField} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';

export default function ControlledTextField(props) {
    const {name, rules, label, options, views, inputFormat, readOnly, ...rest} = props;

    return (
        <Controller
            name={name}
            rules={rules}
            render={({field, fieldState: {error}}) => {
                const {value, onChange, ...restField} = field;

                return (
                    <DatePicker
                        label={label}
                        inputFormat="DD/MM/yyyy"
                        readOnly={readOnly}
                        {...{
                            ...(inputFormat && {inputFormat}),
                            ...(views && {views})
                        }}
                        {...restField}
                        value={value || null}
                        onChange={date => onChange(date && date.toDate())}
                        renderInput={(params) => <TextField {...params} {...rest} helperText={null} />}
                    />
                );
            }}
        />
    );
};
