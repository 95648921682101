import React, {useState, useEffect, useCallback, useContext} from 'react';
import {useNavigate, Link as RouterLink} from 'react-router-dom';
import {Button, Box, FormControlLabel, Checkbox, useMediaQuery} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useTheme} from '@mui/material/styles';
import {getFirestore, collection, getDocs, query, orderBy} from 'firebase/firestore';
import CheckIcon from '@mui/icons-material/Check';
import moment from 'moment';
import {useForm, FormProvider} from 'react-hook-form';
import parsePhoneNumber from 'libphonenumber-js';

import firebaseApp from '../firebase';
import {hasFeature} from '../features';
import {UserContext} from '../contexts/User';

import {Ranks} from '../data/utils';

import StatefulDataGrid from '../components/StatefulDataGrid';

import UserAvatar from '../components/UserAvatar';

import TextField from '../form/TextField';

const Members = () => {
    const [loading, setLoading] = useState(true);
    const [includeDeactivated, setIncludeDeactivated] = useState(false);
    const [includeRDCO, setIncludeRDCO] = useState(false);
    const [docs, setDocs] = useState([]);
    const navigate = useNavigate();
    const db = getFirestore(firebaseApp);
    const {enqueueSnackbar} = useSnackbar();
    const {currentUser} = useContext(UserContext);
    const {isOfficer, isAdmin} = currentUser;
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const methods = useForm({
        defaultValues: {
            filter: '',
            includeRDCO: false,
            includeDeactivated: false
        },
        mode: 'onChange'
    });
    const {watch} = methods;
    const filter = watch('filter');

    useEffect(() => {
        let isSubscribed = true;

        async function fetch() {
            try {
                const ref = collection(db, 'users');
                const q = query(ref, orderBy('lastName', 'asc'));
                const raw = await getDocs(q);
                let docs = [];

                raw.forEach(doc => {
                    const data = doc.data();

                    docs.push({
                        id: doc.id,
                        uid: doc.id,
                        ...data
                    });
                });
                
                if (!includeDeactivated) {
                    docs = docs.filter(doc => doc.deactivated !== true);
                }

                console.log({includeRDCO})
                
                if (!includeRDCO) {
                    docs = docs.filter(doc => doc.role !== 'RDCO');
                }

                docs = docs.filter(doc => doc.role !== 'STATION');

                console.log({docs})

                if (isSubscribed) {
                    setDocs(docs);
                }
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }

            if (isSubscribed) {
                setLoading(false);
            }
        }

        fetch();
        
        return () => isSubscribed = false;
    }, [enqueueSnackbar, db, includeDeactivated, includeRDCO]);

    const renderImageCell = useCallback(params => {
        const {row} = params;

        return (
            <UserAvatar user={row} />
        );
    }, []);

    const columns = [
        {field: 'imageUrl', headerName: '', align: 'center', width: isSmall ? 40 : 60, renderCell: renderImageCell, sortable: false},
        {
            field: 'lastName',
            headerName: 'Member',
            flex: 2,
            renderCell: params => {
                const {row} = params || {};
                const {fullName} = row || {};
                return fullName;
            }
        },
        {field: 'email', headerName: 'Email', flex: 2, sortable: false},
        {field: 'station', headerName: 'Station', width: 100},
        {
            field: 'role', 
            headerName: 'Rank',
            width: 190,
            valueFormatter: params => {
                const {value} = params || {};
                return Ranks[value];
            }
        }
    ];

    if (isOfficer) {
        columns.push({
            field: 'employeeId',
            headerName: 'Employee ID',
            width: 100,
            valueFormatter: params => {
                const {value} = params || {};
                return (value || '').toUpperCase()
            }
        });

        columns.push({
            field: 'dob',
            headerName: 'DOB',
            width: 100,
            valueFormatter: params => {
                const {value} = params || {};
                if (!value) {
                    return '';
                }

                return moment(value.toDate()).format('DD/MM/YYYY');
            }
        });

        columns.push({
            field: 'jibcNumber',
            headerName: 'JIBC #',
            width: 100,
            valueFormatter: params => {
                const {value} = params || {};
                return (value || '').toUpperCase()
            }
        });

        columns.push({
            field: 'phone',
            headerName: 'Phone #',
            width: 130,
            valueFormatter: params => {
                const {value} = params || {};
                const parsed = parsePhoneNumber(`${(value || '')}`, 'CA');

                if (parsed) {
                    return parsed.formatNational();
                }

                return '';
            }
        });

        columns.push({
            field: 'transcriptDate',
            headerName: 'Transcript Updated',
            width: 120,
            valueFormatter: params => {
                const {value} = params || {};
                return value ? moment(value.toDate()).format('L') : '-';
            }
        });

        if (hasFeature('hasJIBCConsent')) {
            columns.push({
                field: 'jibcConsentDate',
                headerName: 'JIBC Consent',
                width: 140,
                sortable: true,
                align: 'center',
                renderCell: params => {
                    const {value} = params || {};
                    if (!value) {
                        return undefined;
                    }

                    return <CheckIcon />;
                }
            });
        }
    }

    if (isAdmin) {
        columns.push({
            field: 'age',
            headerName: 'Age',
            width: 100,
            valueGetter: params => {
                const {row} = params || {};
                const {dob: value} = row || {};
                if (!value) {
                    return;
                }

                return moment().diff(value.toDate(), 'years');
            }
        });

        columns.push({
            field: 'daysUntilDob',
            headerName: 'Days until DOB',
            width: 140,
            valueGetter: params => {
                const {row} = params || {};
                const {dob: value} = row || {};
                if (!value) {
                    return;
                }

                const year = moment().year();
                const dob = moment(value.toDate());
                dob.set('year', year);

                let diff = moment(dob).diff(moment(), 'days');
                if (diff < 0) {
                    dob.set('year', year + 1);
                    diff = moment(dob).diff(moment(), 'days');
                }

                return diff;
            }
        });

        columns.push({
            field: 'registered',
            headerName: 'Registered',
            width: 100,
            sortable: true,
            align: 'center',
            renderCell: params => {
                const {value} = params || {};
                if (!value) {
                    return '';
                }

                return <CheckIcon />;
            }
        });

        if (hasFeature('authIssues')) {
            columns.push({
                field: 'hasAuthIssues',
                headerName: 'Auth Issues',
                width: 100,
                sortable: true,
                align: 'center',
                valueGetter: params => {
                    const {row} = params || {};
                    return row && row.uid !== row.id;
                },
                renderCell: params => {
                    const {value} = params || {};
                    if (!value) {
                        return '';
                    }

                    return <CheckIcon />;
                }
            });
        }
    }

    const onRowClick = useCallback((params, e) => {
        const {metaKey} = e;
        const {row} = params;

        const url = `/members/${row.id}`;
        if (metaKey) {
            window.open(url);
            return;
        }

        navigate(url);
    }, [navigate]);

    const search = filter.trim().toLowerCase();
    const filtered = docs.filter(doc => {
        const {fullName} = doc;
        
        if (fullName.toLowerCase().includes(search)) {
            return true;
        }

        return false;
    });

    return (
        <FormProvider {...methods}>
            <Box sx={{display: 'flex', mb: 1}}>
                <TextField
                    fullWidth
                    label="Search"
                    name="filter"
                    sx={{flex: 1}}
                />

                {isOfficer && (
                    <FormControlLabel
                        control={(
                            <Checkbox
                                onChange={() => setIncludeDeactivated(!includeDeactivated)}
                                checked={includeDeactivated}
                            />
                        )}
                        label="Include Deactivated"
                        sx={{ml: 1}}
                    />
                )}

                {isAdmin && (
                    <FormControlLabel
                        control={(
                            <Checkbox
                                onChange={() => setIncludeRDCO(!includeRDCO)}
                                checked={includeRDCO}
                            />
                        )}
                        label="Include RDCO"
                    />
                )}
            </Box>

            <div style={{display: 'flex', height: '100%'}}>
                <div style={{flexGrow: 1}}>
                    <StatefulDataGrid
                        stateId="members"
                        initialState={{
                            sorting: {
                                sortModel: [
                                    {field: 'station', sort: 'asc'},
                                    {field: 'lastName', sort: 'asc'}
                                ]
                            },
                            columns: {
                                columnVisibilityModel: {
                                    email: false,
                                    dob: false,
                                    jibcNumber: false,
                                    transcriptDate: false,
                                    jibcConsentDate: false,
                                    employeeId: false,
                                    age: false,
                                    daysUntilDob: false,
                                    registered: false
                                }
                            }
                        }}
                        loading={loading}
                        autoHeight
                        rows={filtered}
                        columns={columns}
                        pageSizeOptions={[]}
                        disableRowSelectionOnClick
                        disableColumnFilter
                        onRowClick={onRowClick}
                        getRowClassName={params => {
                            const {row} = params;
                            const {deactivated = false} = row || {};
                            const classNames = [];

                            if (deactivated) {
                                classNames.push('deactivated');
                            }

                            return classNames.join(' ');
                        }}
                        sx={{
                            '& .deactivated': {
                                opacity: 0.6
                            }
                        }}
                    />
                </div>
            </div>

            {isOfficer && (
                <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                    <Button component={RouterLink} variant="contained" to="/members/new">Add Member</Button>
                </Box>
            )}
        </FormProvider>
    );
};

export default Members;