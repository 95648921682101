import React, {useState, useCallback, useContext, useEffect, createRef} from "react";
import {Box, Tabs, Tab, Typography, Divider, Card, CardMedia, CardActionArea, Button, useMediaQuery} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useTheme} from '@mui/material/styles';
import {useNavigate} from 'react-router-dom';
import {useForm, FormProvider} from 'react-hook-form';
import {getFirestore, doc, getDoc, updateDoc, collection, addDoc, query, where, getDocs} from 'firebase/firestore';
import {useParams} from 'react-router-dom';
import {getStorage, ref, uploadBytes, getDownloadURL} from 'firebase/storage';
import {useSnackbar} from 'notistack';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {getAuth, signInWithCustomToken} from 'firebase/auth';

import {UserContext} from '../contexts/User';
import firebaseApp from '../firebase';
import {hasFeature} from '../features';

import {processUserName, verifyOfficer, Ranks} from '../data/utils';

import TextField from '../form/TextField.js';
import PasswordField from '../form/PasswordField.js';
import SelectField from '../form/SelectField.js';
import FileField from '../form/FileField.js';
import CheckboxField from '../form/CheckboxField';
import DatePickerField from '../form/DatePickerField';
import PhoneField from '../form/PhoneField';

import MemberSkills from './member/MemberSkills';
import Statistics from './member/Statistics';
import Transcript from './member/Transcript';
import JIBCConsent from './member/JIBCConsent';

const ProfileImageCard = props => {
    const {imageUrl, imageFile, ...rest} = props;
    const imageUploadRef = createRef();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const handleImageUpload = () => {
        imageUploadRef.current.click();
    };

    return (
        <Card {...rest}>
            <CardActionArea
                sx={{display: 'flex', alignItems: 'center', flex: 1, ...(!isSmall || imageFile || imageUrl ? {aspectRatio: '1/1'} : {minHeight: 100})}}
                onClick={handleImageUpload}
            >
                {(imageFile || imageUrl) ? (
                    <CardMedia
                        component="img"
                        image={imageFile ? URL.createObjectURL(imageFile) : imageUrl}
                    />
                ) : (
                    <Typography variant="button">UPLOAD IMAGE</Typography>
                )}
            </CardActionArea>
            <FileField ref={imageUploadRef} name="imageFile" />
        </Card>
    );
};

export default function User(props) {
    const {isProfile, activeTab = 0} = props;

    const {id: uid} = useParams();
    const isNew = !uid && !isProfile;
    const [loading, setLoading] = useState(!!uid);
    const [deleting, setDeleting] = useState(false);
    const [duplicating, setDuplicating] = useState(false);
    const [impersonating, setImpersonating] = useState(false);
    const db = getFirestore(firebaseApp);
    const navigate = useNavigate();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const storage = getStorage(firebaseApp);
    
    const {currentUser, setCurrentUser, member} = useContext(UserContext);
    const [user, setUser] = useState(isProfile ? (member || currentUser) : undefined);
    const functions = getFunctions(firebaseApp);
    const registerUser = httpsCallable(functions, 'registerUser');
    const deleteUser = httpsCallable(functions, 'deleteUser');
    const updateUserPassword = httpsCallable(functions, 'updateUserPassword');
    const impersonateUser = httpsCallable(functions, 'impersonateUser');
    const theme = useTheme();
    const auth = getAuth(firebaseApp);
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const isOfficer = verifyOfficer(currentUser);
    const {isAdmin: currentUserIsAdmin} = currentUser || {};
    const hasAuthIssues = user && user.uid !== user.id;

    const canImpersonate = hasFeature('impersonateUser');

    const methods = useForm({
        defaultValues: {
            email: '',
            role: 'RECRUIT',
            station: '51',
            imageUrl: '',
            imageFile: '',
            employeeId: '',
            jibcNumber: '',
            transcriptUrl: '',
            transcriptFile: '',
            jibcConsentUrl: '',
            jibcConsentFile: '',
            dob: '',
            phone: '',
            ...user
        },
        mode: 'onChange'
    });
    const {handleSubmit, formState, reset, watch} = methods;
    const {isValid} = formState;

    const imageUrl = watch('imageUrl');
    const imageFile = watch('imageFile');
    const password = watch('password');
    const registered = watch('registered');

    useEffect(() => {
        let isSubscribed = true;

        const fetch = async() => {
            if (uid) {
                const ref = doc(db, 'users', uid);
                const raw = await getDoc(ref);
                
                if (isSubscribed) {
                    if (!raw.exists()) {
                        navigate(-1);
                    }

                    const {dob: rawDob, ...rest} = raw.data();
                    const dob = rawDob ? rawDob.toDate() : null;
                    
                    const user = {
                        id: uid,
                        uid,
                        dob,
                        ...rest
                    };

                    reset(user);
                    setUser(user);

                    setLoading(false);
                }
            }
        };

        fetch();

        return () => isSubscribed = false;
    }, [db, reset, uid, navigate]);

    useEffect(() => {
        if (isProfile) {
            setUser(member || currentUser);
            reset(member || currentUser);
        }
    }, [user, member, currentUser, isProfile, reset, navigate, isOfficer]);

    useEffect(() => {
        try {
            const {fullName} = user;
            document.title = `${fullName} | JRFD`;
        } catch(e) {
            document.title = 'JRFD';
        }
    }, [user]);

    const handleImpersonate = useCallback(async() => {
        try {
            setImpersonating(true);

            const {data} = await impersonateUser({uid});
            const {token} = data;

            const currentUserToken = await getAuth(firebaseApp).currentUser.getIdToken();
            localStorage.setItem('impersonating', currentUserToken);

            await signInWithCustomToken(auth, token);
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setImpersonating(false);
    }, [uid, auth, impersonateUser, enqueueSnackbar]); 

    const handleDuplicate = useCallback(() => {
        if (!user) {
            return;
        }

        const {id: existingUid, uid} = user;
        if (existingUid === uid) {
            console.error('Cannot duplicate user with same id and uid');
            return;
        }

        const duplicate = async() => {
            setDuplicating(true);

            await registerUser({uid, existingUid});

            window.location.reload();
        };

        enqueueSnackbar('Are you sure you want to do this? You should not do this unless you are Robert', {
            variant: 'warning',
            action: key => {
                return (
                    <>
                        <Button onClick={() => {
                            closeSnackbar(key);
                            duplicate();
                        }}>
                            Execute
                        </Button>
                        <Button onClick={() => closeSnackbar(key)}>
                            Dismiss
                        </Button>
                    </>
                );
            }
        });
    }, [user, enqueueSnackbar, closeSnackbar, setDuplicating, registerUser]);

    const handleDelete = useCallback(() => {
        const onDelete = async() => {
            try {
                setDeleting(true);

                await deleteUser({uid});

                navigate(-1);
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }
    
            setDeleting(false);
        };

        enqueueSnackbar('Are you sure you want to delete this member? This will also delete this account', {
            variant: 'warning',
            action: key => {
                return (
                    <>
                        <Button onClick={() => {
                            closeSnackbar(key);
                            onDelete();
                        }}>
                            Delete
                        </Button>
                        <Button onClick={() => closeSnackbar(key)}>
                            Dismiss
                        </Button>
                    </>
                );
            }
        });
    }, [enqueueSnackbar, navigate, uid, closeSnackbar, deleteUser]);

    const onSubmit = useCallback(async data => {
        setLoading(true);

        const {email, password, role, station, jibcNumber, dob, deactivated = false, isAdmin = false, employeeId, phone} = data;

        try {
            if (isNew) {
                const newData = processUserName({
                    email,
                    station,
                    role,
                    dob,
                    jibcNumber,
                    employeeId,
                    phone
                });
                
                const ref = collection(db, 'users');
                const q = query(ref, where('email', '==', email));
                const raw = await getDocs(q);
                const {size} = raw;
                
                if (size > 0) {
                    enqueueSnackbar(`Member with email '${email}' already exists`, {variant: 'error'});
                } else {
                    await addDoc(ref, newData);

                    navigate(-1);
                }
            } else {
                const {id} = data;
                const docRef = doc(db, 'users', id);

                let imageUrl;
                if (imageFile) {
                    const reference = ref(storage, `/users/${id}.jpg`);
                    await uploadBytes(reference, imageFile);
                    
                    imageUrl = await getDownloadURL(reference);
                }
    
                const newData = processUserName({
                    email,
                    station,
                    jibcNumber: (jibcNumber || '').toUpperCase(),
                    dob,
                    phone,
                    ...(isOfficer && {
                        role,
                        employeeId
                    }),
                    ...(currentUserIsAdmin && {
                        deactivated,
                        isAdmin
                    }),
                    ...(imageUrl && {imageUrl})
                });
                
                await updateDoc(docRef, newData);

                if (password) {
                    await updateUserPassword({uid: id, password});
                }
    
                if (isProfile) {
                    setCurrentUser({
                        ...currentUser,
                        ...newData
                    });
                }

                navigate(-1);
            }
        } catch (e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setLoading(false);
    }, [db, navigate, isOfficer, updateUserPassword, currentUserIsAdmin, enqueueSnackbar, imageFile, storage, currentUser, setCurrentUser, isProfile, isNew]);

    const handleTabChange = useCallback((event, activeTab) => {
        const base = isProfile ? '/profile' : `/members/${uid}`;

        if (activeTab === 0) {
            navigate(`${base}`);
        } else if (activeTab === 1) {
            navigate(`${base}/skills`);
        } else if (activeTab === 2) {
            navigate(`${base}/statistics`);
        } else if (activeTab === 3) {
            navigate(`${base}/transcript`);
        } else if (activeTab === 4) {
            navigate(`${base}/jibcConsent`);
        }
    }, [navigate, uid, isProfile]);

    const tabs = [
        {label: isNew ? 'New Member' : isProfile ? 'Edit Profile' : 'Edit Member'},
        {label: 'Skills'},
        {label: 'Transcript'}
    ];

    if (hasFeature('jibcConsent')) {
        tabs.push({label: 'JIBC Consent'});
    }

    if (hasFeature('memberStatistics')) {
        tabs.push({label: 'Statistics'});
    }

    return (
        <FormProvider {...methods}>
            <Box sx={{borderBottom: 1, borderColor: 'divider', mb: 2}}>
                <Tabs value={Math.min(activeTab, tabs.length - 1)} onChange={handleTabChange}>
                    {tabs.map((tab, index) => (
                        <Tab key={index} label={tab.label} />
                    ))}
                </Tabs>
            </Box>

            {activeTab === 0 && (
                <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1, display: 'flex', flexDirection: isSmall ? 'column' : 'row'}}>
                    {!isNew && (
                        <Box sx={{flex: 1, mr: isSmall ? 0 : 2, ...(isSmall && {display: 'flex', justifyContent: 'center'})}}>
                            <ProfileImageCard sx={{maxWidth: isSmall && (imageUrl || imageFile) ? 150 : 1500, flex: !imageUrl && !imageFile ? 1 : undefined}} imageFile={imageFile} imageUrl={imageUrl} />
                        </Box>
                    )}
                    <Box sx={{flex: isSmall ? 1 : 3, mt: !isNew && isSmall ? 2 : 0}}>
                        <TextField
                            sx={{mt: 2}}
                            required
                            fullWidth
                            label="Email"
                            name="email"
                            autoComplete="email"
                            disabled={!isNew || loading}
                            rules={{
                                required: true,
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: 'Invalid email address'
                                }
                            }}
                        />
                        {!isNew && ((currentUserIsAdmin && registered) || isProfile) && (
                            <PasswordField
                                sx={{mt: 2}}
                                fullWidth
                                label="New Password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                disabled={loading}
                            />
                        )}
                        {password && (
                            <>
                                <PasswordField
                                    sx={{mt: 2}}
                                    rules={{
                                        required: true,
                                        validate: value => value !== password ? 'Passwords do not match' : true
                                    }}
                                    fullWidth
                                    label="Confirm Password"
                                    name="confirmPassword"
                                    autoComplete="new-password"
                                    type="password"
                                    disabled={loading}
                                />
                            </>
                        )}
                        <Box sx={{display: 'flex'}}>
                            {isOfficer && (
                                <SelectField
                                    sx={{mt: 2, mr: 1}}
                                    margin="normal"
                                    fullWidth
                                    label="Rank"
                                    name="role"
                                    disabled={loading}
                                    options={Object.keys(Ranks).map(value => {
                                        return {
                                            value,
                                            label: Ranks[value]
                                        };
                                    })}
                                />
                            )}
                            <SelectField
                                sx={{mt: 2}}
                                fullWidth
                                required
                                label="Station"
                                name="station"
                                disabled={loading}
                                options={[
                                    {value: '51', label: 'Station 51'},
                                    {value: '52', label: 'Station 52'}
                                ]}
                            />
                        </Box>

                        <Typography variant="h6" sx={{mt: 4, mb: 1}}>Personal Information</Typography>
                        <Divider />

                        <Box sx={{display: 'flex', pt: 2}}>
                            <Box sx={{flex: 1}}>
                                <DatePickerField
                                    label="Date of Birth"
                                    name="dob"
                                    fullWidth
                                    disabled={loading}
                                />
                            </Box>

                            {(isOfficer || isProfile) && (
                                <Box sx={{flex: 1, pr: 1}}>
                                    <PhoneField
                                        sx={{ml: 1}}
                                        fullWidth
                                        label="Phone #"
                                        name="phone"
                                        disabled={loading}
                                    />
                                </Box>
                            )}
                        </Box>

                        {currentUserIsAdmin && (
                            <TextField
                                sx={{mt: 2}}
                                fullWidth
                                label="Employee ID"
                                name="employeeId"
                                disabled={loading}
                            />
                        )}

                        <Typography variant="h6" sx={{mt: 4, mb: 1}}>JIBC Information</Typography>
                        <Divider />

                        <TextField
                            sx={{mt: 2}}
                            fullWidth
                            label="JIBC #"
                            name="jibcNumber"
                            disabled={loading}
                        />

                        {currentUserIsAdmin && (
                            <>
                                <Divider sx={{mt: 2}} />

                                <CheckboxField sx={{mt: 2, mb: 2}} name="deactivated" label="No longer active?" />
                                <CheckboxField sx={{mt: 2, mb: 2}} name="isAdmin" label="Is Admin?" />

                                <Divider />
                            </>   
                        )}

                        <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                            {currentUserIsAdmin && hasAuthIssues && (
                                <LoadingButton
                                    size="large"
                                    onClick={handleDuplicate}
                                    disabled={duplicating}
                                    loading={duplicating}
                                    sx={{mr: 1}}
                                >
                                    Fix auth issues
                                </LoadingButton>
                            )}

                            {currentUserIsAdmin && canImpersonate && (
                                <LoadingButton
                                    size="large"
                                    onClick={handleImpersonate}
                                    disabled={impersonating}
                                    loading={impersonating}
                                    sx={{mr: 1}}
                                >
                                    Impersonate
                                </LoadingButton>
                            )}

                            {isOfficer && (
                                <LoadingButton
                                    size="large"
                                    onClick={handleDelete}
                                    disabled={deleting}
                                    loading={deleting}
                                    sx={{mr: 1}}
                                >
                                    Delete
                                </LoadingButton>
                            )}

                            <LoadingButton
                                type="submit"
                                size="large"
                                variant="contained"
                                onClick={handleSubmit(onSubmit)}
                                disabled={loading || !isValid}
                                loading={loading}
                            >
                                Save
                            </LoadingButton>
                        </Box>
                    </Box>
                </Box>
            )}

            {activeTab === 1 && (
                <MemberSkills user={member || user} />
            )}

            {activeTab === 2 && (
                <Transcript user={member || user} />
            )}

            {activeTab === 3 && (
                <JIBCConsent user={member || user} />
            )}

            {activeTab === 4 && (
                <Statistics user={member || user} />
            )}
        </FormProvider>
    );
}