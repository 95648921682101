import React, {useState, useEffect, useContext} from 'react';
import {TextField, Autocomplete} from '@mui/material';
import {Controller} from 'react-hook-form';
import {getFirestore, collection, getDocs, query, orderBy, where} from 'firebase/firestore';
import {useSnackbar} from 'notistack';

import {UserContext} from '../contexts/User';
import firebaseApp from '../firebase';

export default function ControlledAutocompleteField(props) {
    const {name, label, rules, ...rest} = props;

    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState([]);
    const {currentUser} = useContext(UserContext);
    const {isAdmin} = currentUser;

    const db = getFirestore(firebaseApp);
    const {enqueueSnackbar} = useSnackbar();
    
    useEffect(() => {
        let isSubscribed = true;

        async function fetch() {
            try {
                let ref = collection(db, 'apparatus');
                let q = query(ref, orderBy('station', 'asc'));
                let raw = await getDocs(q);
                let docs = [];

                raw.forEach(doc => {
                    const data = doc.data();

                    docs.push({
                        id: doc.id,
                        uid: doc.id,
                        ...data
                    });
                });

                ref = collection(db, 'users');
                q = query(ref, where('role', '==', 'STATION'));
                raw = await getDocs(q);

                raw.forEach(doc => {
                    const data = doc.data();

                    docs.push({
                        id: doc.id,
                        uid: doc.id,
                        ...data
                    });
                });

                if (isSubscribed) {
                    setOptions(docs.map(doc => {
                        const {id, tag, fullName} = doc;

                        return {
                            value: id,
                            label: tag || fullName
                        };
                    }));
                }
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }

            if (isSubscribed) {
                setLoading(false);
            }
        }

        fetch();
        
        return () => isSubscribed = false;
    }, [enqueueSnackbar, db, currentUser, isAdmin]);

    return (
        <Controller
            name={name}
            rules={rules}
            render={({field, fieldState: {error}}) => {
                const {value, onChange, ...restField} = field;

                return (
                    <Autocomplete
                        {...rest}
                        {...restField}
                        value={value || []}
                        options={options}
                        onChange={(e, newValue) => {
                            onChange(newValue);
                        }}
                        getOptionLabel={option => option.label || ''}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={label}
                            />
                        )}
                    />
                );
            }}
        />
    );
};
