import React, {useState, useEffect, useCallback} from 'react';
import {Box} from '@mui/material';
import {getFirestore, writeBatch, doc} from 'firebase/firestore';
import {useSnackbar} from 'notistack';
import {omit} from 'lodash';
import {useNavigate, useLocation} from 'react-router-dom';
import {LoadingButton} from '@mui/lab';

import firebaseApp from '../../firebase.js';

import IncidentsGrid from './Grid';

const ImportButton = ({selectionCount, ...rest}) => {
    return (
        <LoadingButton variant="contained" {...rest}>
            Import Selected ({selectionCount})
        </LoadingButton>
    );
};

export default function Import(props) {
    const {state} = useLocation();
    const {incidents = []} = state || {};

    const [importing, setImporting] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const db = getFirestore(firebaseApp);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!incidents.length) {
            navigate('/incidents');
        }
    }, [incidents, navigate]);

    const handleImport = useCallback(async () => {
        const incidentsToImport = incidents.filter(incident => {
            const {uniqueId} = incident;
            return rowSelectionModel.includes(uniqueId);
        });

        if (!incidentsToImport.length) {
            enqueueSnackbar('No incidents selected', {variant: 'error'});
            return;
        }

        setImporting(true);

        try {
            const batch = writeBatch(db);

            for (const incident of incidentsToImport) {
                const {uniqueId} = incident;
                const incidentRef = doc(db, 'incidents', uniqueId);

                const data = omit(incident, ['foundExisting', 'issues']);

                batch.set(incidentRef, data);
            }

            await batch.commit();
            
            enqueueSnackbar('Incidents imported', {variant: 'success'});

            navigate('/incidents');
        } catch(e) {
            enqueueSnackbar(`Error importing incidents: ${e}`, {variant: 'error'});
        }

        setImporting(false);
    }, [enqueueSnackbar, incidents, rowSelectionModel, db, navigate]);

    return (
        <Box>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                <ImportButton loading={importing} onClick={handleImport} selectionCount={rowSelectionModel.length} sx={{mb: 2}} />
            </Box>

            <IncidentsGrid
                incidents={incidents}
                editMode="row"
                getRowId={row => row.uniqueId}
                checkboxSelection
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
                getRowClassName={params => {
                    const {row} = params;
                    const {foundExisting = false, issues = []} = row || {};
                    const classNames = [];

                    if (foundExisting) {
                        classNames.push('duplicate');
                    }

                    if (issues.length) {
                        classNames.push('has-issues');
                    }

                    return classNames.join(' ');
                }}
                sx={{
                    '& .has-issues': {
                        backgroundColor: 'rgba(250, 250, 0, .1)'
                    },
                    '& .duplicate': {
                        borderLeft: '5px solid rgba(255, 0, 0, 0.9)',
                        borderRight: '5px solid rgba(255, 0, 0, 0.9)'
                    }
                }}
                columns={[
                    {
                        field: 'foundExisting',
                        headerName: 'Duplicate',
                        width: 100,
                        renderCell: params => {
                            const {row} = params;
                            const {foundExisting = false} = row || {};
                            return foundExisting ? 'Yes' : 'No';
                        }
                    }
                ]}
            />

            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                <ImportButton loading={importing} onClick={handleImport} selectionCount={rowSelectionModel.length} sx={{mt: 2}} />
            </Box>
        </Box>
    );
}
