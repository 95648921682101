import React, {useState, useCallback, useContext, useEffect, createRef} from "react";
import {Box, Grid, Card, CardMedia, CardActionArea, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {getFirestore, doc, collection, getDoc, addDoc, updateDoc} from 'firebase/firestore';
import {useForm, FormProvider} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {getStorage, ref, uploadBytes, getDownloadURL} from 'firebase/storage';
import {useSnackbar} from 'notistack';

import {UserContext} from '../contexts/User';
import firebaseApp from '../firebase';

import TextField from '../form/TextField.js';
import FileField from '../form/FileField.js';
import SelectField from '../form/SelectField.js';

export default function Apparatus(props) {
    const {id: uid} = useParams();
    const isNew = !uid;

    const [loading, setLoading] = useState(!isNew);
    const db = getFirestore(firebaseApp);
    const {currentUser} = useContext(UserContext);
    const navigate = useNavigate();
    const storage = getStorage(firebaseApp);
    const imageUploadRef = createRef();
    const {enqueueSnackbar} = useSnackbar();

    const {isAdmin} = currentUser;

    const methods = useForm({
        defaultValues: {
            type: 'ENGINE',
            station: '51',
            color: '',
            imageUrl: '',
            imageFile: ''
        },
        mode: 'onChange'
    });
    const {handleSubmit, formState, reset, watch} = methods;
    const {isValid} = formState;

    const imageUrl = watch('imageUrl');
    const imageFile = watch('imageFile');

    useEffect(() => {
        let isSubscribed = true;

        if (isNew && !isAdmin) {
            navigate('/apparatus');
            return;
        }

        const fetch = async() => {
            if (uid && !isNew) {
                const docRef = doc(db, 'apparatus', uid);
                const raw = await getDoc(docRef);
                
                if (isSubscribed) {
                    reset(raw.data());

                    setLoading(false);
                }
            }
        };

        fetch();

        return () => isSubscribed = false;
    }, [db, isNew, reset, uid, isAdmin, navigate]);

    const handleImageUpload = () => {
        imageUploadRef.current.click();
    };

    const onSubmit = useCallback(async data => {
        setLoading(true);

        try {
            let imageUrl;
            
            const {type, station, color} = data;
            const tag = `${type[0]}${station}`;

            if (isNew) {
                const docRef = collection(db, 'apparatus');

                const {id} = await addDoc(docRef, {
                    type,
                    station,
                    color,
                    tag
                });

                navigate(`/${id}`);
            } else {
                if (imageFile) {
                    const reference = ref(storage, `/apparatus/${uid}.jpg`);
                    await uploadBytes(reference, imageFile);
                    
                    imageUrl = await getDownloadURL(reference);
                }

                const docRef = doc(db, 'apparatus', uid);
                await updateDoc(docRef, {
                    type,
                    station,
                    color,
                    tag,
                    ...(imageUrl && {imageUrl})
                });

                navigate(`/${uid}`);
            }
        } catch (e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setLoading(false);
    }, [db, isNew, uid, navigate, storage, imageFile, enqueueSnackbar]);

    return (
        <FormProvider {...methods}>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}}>
                <Grid container spacing={2}>
                    {!isNew && (
                        <Grid item xs={4}>
                            <Card>
                                <CardActionArea
                                    sx={{minHeight: 260, display: 'flex', alignItems: 'center'}}
                                    onClick={handleImageUpload}
                                >
                                    {(imageFile || imageUrl) ? (
                                        <>
                                            <CardMedia
                                                component="img"
                                                height={260}
                                                image={imageFile ? URL.createObjectURL(imageFile) : imageUrl}
                                            />
                                        </>
                                    ) : (
                                        <Typography variant="button">UPLOAD IMAGE</Typography>
                                    )}
                                </CardActionArea>
                                <FileField ref={imageUploadRef} name="imageFile" />
                            </Card>
                        </Grid>
                    )}
                    <Grid item xs={isNew ? 12 : 8}>
                        <SelectField
                            sx={{mt: 2}}
                            fullWidth
                            required
                            label="Type"
                            name="type"
                            disabled={loading}
                            options={[
                                {value: 'ENGINE', label: 'Engine'},
                                {value: 'PUMPER', label: 'Pumper'},
                                {value: 'TENDER', label: 'Tender'},
                                {value: 'BUSH', label: 'Bush Truck'},
                                {value: 'RESCUE', label: 'Rescue Truck'},
                                {value: 'UTILITY', label: 'Utility Truck'},
                                {value: 'CAR', label: 'Car'}
                            ]}
                        />
                        <SelectField
                            sx={{mt: 2}}
                            fullWidth
                            required
                            label="Station"
                            name="station"
                            disabled={loading}
                            options={[
                                {value: '51', label: 'Station 51'},
                                {value: '52', label: 'Station 52'}
                            ]}
                        />
                        <TextField
                            sx={{mt: 2}}
                            fullWidth
                            label="Color"
                            name="color"
                            disabled={loading}
                        />

                        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                sx={{mt: 2}}
                                onClick={handleSubmit(onSubmit)}
                                disabled={loading || !isValid}
                                loading={loading}
                            >
                                Save Apparatus
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </FormProvider>
    );
}