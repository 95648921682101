import React, {useState, useCallback} from "react";
import {Button, Typography, Box, Container} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail} from 'firebase/auth';
import {useForm, FormProvider} from 'react-hook-form';
import {getFirestore, query, collection, where, getDocs} from 'firebase/firestore';
import {useSnackbar} from 'notistack';
import {getFunctions, httpsCallable} from 'firebase/functions';

import firebaseApp from '../firebase';

import TextField from '../form/TextField.js';
import PasswordField from '../form/PasswordField.js';

export default function Login() {
    const [loading, setLoading] = useState(false);
    const [loggingIn, setLoggingIn] = useState(false);
    const [registering, setRegistering] = useState(false);
    const [error, setError] = useState(null);
    const auth = getAuth(firebaseApp);
    const db = getFirestore(firebaseApp);
    const {enqueueSnackbar} = useSnackbar();

    const functions = getFunctions(firebaseApp);
    const registerUser = httpsCallable(functions, 'registerUser');

    const methods = useForm({
        defaultValues: {
            email: '',
            // email: 'robert.dougan@rdco.com',
            password: '',
            confirmPassword: '',
            // password: 'password',
            // confirmPassword: 'password',
            station: '51'
        },
        mode: 'onChange'
    });
    const {handleSubmit, formState, watch} = methods;
    const {isValid} = formState;

    const email = watch('email');
    const password = watch('password');

    const onResetPassword = useCallback(async () => {
        setLoading(true);

        try {
            await sendPasswordResetEmail(auth, email);

            enqueueSnackbar(`Reset password email sent to ${email}. Please check your email.`);
        } catch (e) {
            setLoading(false);

            setError(e.message);
        }

        setLoading(false);
    }, [auth, email, enqueueSnackbar]);

    const onCancel = useCallback(() => {
        setRegistering(false);
        setLoggingIn(false);
    }, []);

    const onSubmit = useCallback(async data => {
        setLoading(true);

        const {password} = data;
        let {email = ''} = data;
        email = email.toLowerCase().trim();
        const isStation = email.match('51|52@rdco.com');

        try {
            const ref = collection(db, 'users');
            const q = query(ref, where('email', '==', email));
            const raw = await getDocs(q);
            let rawDoc;

            raw.forEach(doc => {
                rawDoc = doc;
            });

            if (registering) {
                const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                const {user} = userCredential;
                const {uid} = user;

                const existingUid = rawDoc.id;

                await registerUser({uid, existingUid});
            } else if (isStation || loggingIn) {
                await signInWithEmailAndPassword(auth, email, password || 'password');
            } else {
                if (!rawDoc) {
                    throw new Error('No user found with that email address');
                }

                const {registered} = rawDoc.data();
                if (registered) {
                    setLoggingIn(true);
                } else {
                    setRegistering(true);
                }

                setLoading(false);
            }
        } catch (e) {
            setLoading(false);

            setError(e.message);
        }
    }, [auth, registering, loggingIn, db, registerUser]);

    return (
        <FormProvider {...methods}>
            <Container component="main" maxWidth="s" sx={{maxWidth: 400}}>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'stretch',
                    }}
                >
                    <Box sx={{mt: 1, display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                        <img src="/logo.png" alt="JRFD" style={{width: '200px'}} />
                    </Box>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}}>
                        <TextField
                            sx={{mt: 2}}
                            fullWidth
                            label="Email"
                            name="email"
                            autoComplete="email"
                            disabled={loading || loggingIn || registering}
                            rules={{
                                required: true,
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@rdco.com$/i,
                                    message: 'You must use an RDCO email address'
                                }
                            }}
                            {...{
                                ...(error && {error: true, helperText: error})
                            }}
                        />

                        {(loggingIn || registering) && (
                            <PasswordField
                                sx={{mt: 2}}
                                fullWidth
                                label="Password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                disabled={loading}
                                rules={{required: loggingIn}}
                            />
                        )}
                        {registering && (
                            <>
                                <PasswordField
                                    sx={{mt: 2}}
                                    rules={{
                                        required: true,
                                        validate: value => value !== password ? 'Passwords do not match' : true
                                    }}
                                    fullWidth
                                    label="Confirm Password"
                                    name="confirmPassword"
                                    autoComplete="new-password"
                                    type="password"
                                    disabled={loading}
                                />
                            </>
                        )}
                        <LoadingButton
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 2}}
                            onClick={handleSubmit(onSubmit)}
                            disabled={loading || !isValid}
                            loading={loading}
                        >
                            {registering ? 'Register' : loggingIn ? 'Sign In' : 'Next'}
                        </LoadingButton>
                        {loggingIn && (
                            <Button
                                fullWidth
                                variant="outlined"
                                sx={{mt: 1}}
                                onClick={onResetPassword}
                                disabled={loading}
                            >
                                Reset Password
                            </Button>
                        )}
                        {(loggingIn || registering) && (
                            <>
                                <Button
                                    fullWidth
                                    sx={{mt: 1}}
                                    onClick={onCancel}
                                    disabled={loading}
                                >
                                    Cancel
                                </Button>
                            </>
                        )}

                        {!loggingIn && !registering && (
                            <>
                                <Typography component="div" variant="caption" sx={{mt: 2, textAlign: 'center'}}>OR</Typography>

                                <LoadingButton
                                    fullWidth
                                    variant="contained"
                                    sx={{mt: 2}}
                                    onClick={() => onSubmit({email: '51@rdco.com'})}
                                    disabled={loading}
                                    loading={loading}    
                                >
                                    Login as Station 51
                                </LoadingButton>

                                <LoadingButton
                                    fullWidth
                                    variant="contained"
                                    sx={{mt: 1}}
                                    onClick={() => onSubmit({email: '52@rdco.com'})}
                                    disabled={loading}
                                    loading={loading}    
                                >
                                    Login as Station 52
                                </LoadingButton>
                            </>
                        )}
                    </Box>
                </Box>
            </Container>
        </FormProvider>
    );
}