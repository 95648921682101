import {collection, query, where, getDocs, documentId} from 'firebase/firestore';
import {get, chunk, capitalize, uniq} from 'lodash';
import {red, grey, yellow, green, brown, orange, blue, purple, pink} from '@mui/material/colors';
import {getAuth} from 'firebase/auth';
import axios from 'axios';

import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import CheckIcon from '@mui/icons-material/Check';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';

import firebaseApp from '../firebase';

let cachedUsers = {};

export const IncidentTypes = {
    MVI: 'Motor Vehicle Incident',
    FMR: 'First Medical Response',
    BURNING_COMPLAINT: 'Burning Complaint',
    LINE_DOWN: 'Line Down',
    ASSIST: 'Assist',
    STRUCTURE_FIRE: 'Structure Fire',
    WILDLAND_FIRE: 'Wildland Fire',
    VEHICLE_FIRE: 'Vehicle Fire',
    ALARM: 'Alarm',
    RECHECK: 'Recheck',
    SERVICE_CALL: 'Service Call',
    MUTUAL_AID: 'Mutual Aid',
    HAZMAT: 'Hazmat',
    UNKNOWN: 'Unknown'
};

export const colorForIncidentType = type => {
    if (!type) {
        return grey[200];
    }

    if (type === 'FMR') {
        return red[500];
    } else if (type === 'STRUCTURE_FIRE') {
        return yellow[500];
    } else if (type === 'RECHECK') {
        return yellow[100];
    } else if (type === 'WILDLAND_FIRE') {
        return green[500];
    } else if (type === 'BURNING_COMPLAINT') {
        return brown[500];
    } else if (type === 'MVI') {
        return blue[500];
    } else if (type === 'LINE_DOWN') {
        return orange[200];
    } else if (type === 'ALARM') {
        return yellow[300];
    } else if (type === 'MUTUAL_AID') {
        return green[200];
    } else if (type === 'ASSIST') {
        return purple[200];
    } else if (type === 'SERVICE_CALL') {
        return purple[500];
    } else if (type === 'HAZMAT') {
        return pink[200];
    }

    return grey[200];
};

const fetchDocumentsWithIds = async(db, ref, ids, returnObject) => {
    const data = returnObject ? {} : [];

    if (!ids || !ids.length) {
        return data;
    }

    const chunks = chunk(ids, 10);

    for (const chunk of chunks) {
        const q = query(ref, where(documentId(), 'in', chunk));
        const raw = await getDocs(q);

        if (raw) {
            raw.forEach(item => {
                const {id} = item;
                const base = {
                    id,
                    uid: id,
                    ...item.data()
                };
                
                if (returnObject) {
                    data[id] = base;
                } else {
                    data.push(base);
                }
            });
        }
    }

    return data;
};

export const populateUser = async(db, doc, isArray) => {
    let userIds;

    if (isArray) {
        const {users = []} = doc;
        userIds = users.filter(Boolean).flat();
    } else {
        const {user} = doc;
        userIds = [user];
    }

    userIds = userIds.filter(Boolean);
    
    if (!userIds.length) {
        return doc;
    }

    const users = await populateCollectionIds(db, 'users', userIds);

    if (isArray) {
        const {users: userIds} = doc;
        
        return {
            ...doc,
            users: userIds.map(userId => users[userId]).filter(Boolean)
        };
    }

    const {user: userId} = doc;
    const user = users[userId] || {};

    return {
        ...doc,
        user
    };
};

export const populateUsers = async(db, docs, isArray) => {
    let userIds;

    if (isArray) {
        userIds = docs.map(doc => doc.users).filter(Boolean).flat();
    } else {
        userIds = docs.map(doc => doc.user).filter(Boolean);
    }

    const users = await populateCollectionIds(db, 'users', userIds);

    return docs.map(doc => {
        if (isArray) {
            const {users: userIds} = doc;

            return {
                ...doc,
                users: userIds.map(userId => users[userId]).filter(Boolean)
            };
        }

        const {user: userId} = doc;
        const user = users[userId] || {};

        return {
            ...doc,
            user
        };
    });
};

export const populateCollectionIds = async(db, ref, ids) => {
    if (typeof ref === 'string') {
        ref = collection(db, ref);
    }

    const toFetch = uniq(ids).filter(id => !cachedUsers[id]);
    if (toFetch.length) {
        const fetched = await fetchDocumentsWithIds(db, ref, toFetch, true);

        cachedUsers = {
            ...cachedUsers,
            ...fetched
        };
    }

    return uniq(ids).reduce((result, userId) => {
        result[userId] = cachedUsers[userId];
        return result;
    }, {});
};

export const processUserName = user => {
    const {email} = user;
    const [unformattedName] = email.split('@');
    let [firstName, lastName] = unformattedName.split('.').map(name => capitalize(name));

    if (lastName === 'Osullivan') {
        lastName = `O'Sullivan`;
    } else if (lastName === 'Markstrom') {
        lastName = `Markström`;
    }
    
    if (['51@rdco.com', '52@rdco.com'].includes(email)) {
        firstName = 'Station';
        lastName = unformattedName;
    }

    const fullName = [firstName || '', lastName || ''].filter(Boolean).join(' ');

    return {
        ...user,
        email: email.toLowerCase(),
        fullName,
        firstName,
        lastName
    };
};

export const getCollection = async(ref) => {
    const q = query(ref);
    const raw = await getDocs(q);
    let docs = [];

    raw.forEach(doc => {
        const {id} = doc;
        const {date: rawDate, ...rest} = doc.data();

        docs.push({
            id,
            uid: id,
            ...(rawDate && rawDate.toDate) && {date: rawDate.toDate()},
            ...rest
        });
    });

    return docs;
};

export const verifyOfficer = user => {
    if (!user) {
        return false;
    }

    const {isAdmin = false, email, role} = user;
    return isAdmin || OfficerRanks.includes(role) || (email && email.match('artiss'));
}

export const Ranks = {
    RECRUIT: 'Recruit',
    EXTERIOR: 'Exterior',
    INTERIOR: 'Interior',
    FULLSERVICE: 'Full Service',
    TEAM_LEADER: 'Team Leader',
    LIEUTENANT: 'Lieutenant',
    CAPTAIN: 'Captain',
    DEPUTY_CHIEF: 'Deputy Chief',
    CHIEF: 'Chief',
    APPARATUS: 'Apparatus',
    STATION: 'Station',
    RDCO: 'RDCO'
};

export const OfficerRanks = [
    'LIEUTENANT',
    'CAPTAIN',
    'DEPUTY_CHIEF',
    'CHIEF'
];

export const DriverOperatorSkills = {
    1: 'Cleaning and Waxing',
    2: 'Perform a routine walk-around maintenance inspection',
    3: 'Perform engine compartment inspection and routine preventive maintenance',
    4: 'Perform weekly inspections for apparatus equipped with a fire pump',
    5: 'Perform a hard intake hose service test',
    6: 'Test Apparatus Road and Parking Brakes',
    7: 'Drive a Fire Service Apparatus',
    8: 'Perform Various Driving Exercises',
    9: 'Perform Various Road Test in a Fire Service Apparatus',
    10: 'Hose Intakes, Hydrant Outlets, Multiple Connections',
    11: 'Dual Pumping and Tandem Pumping',
    12: 'Engage and Disengage a PTO, Engage and Disengage a Pump',
    13: 'Perform Pump Operations from a Water Tank, Transition from water tank to an external pressurized water supply, Operate from a pressurized water source',
    14: 'Draft from a Static Water Supply',
    15: 'Relay',
    16: 'Foam',
    17: 'Fire Apparatus Testing',
    18: 'Mobile Water Supply, Water Shuttle Operation, Portable Water Tank Dump Site, Establish, Operate and Shut Down Mobile Portable Tank'
};

export const Skills = {
    exterior: {
        label: 'Exterior',
        skills: {
            fr3: {
                label: 'FR'
            },
            knowledge: 'Knowledge',
            skills: 'Skills',
            hazmatAwareness: {
                label: 'HAZMAT Awareness',
                width: 160
            },
            ics100: 'ICS 100',
            trafficControl: 'Traffic Control',
            sppWff1: 'WSPP-WFF 1',
            apparatusDriverOperator: {
                label: 'Driver/Operator',
                // width: 170
            },
            airBrakes: 'Air Brakes',
            class3: 'Class 3'
        }
    },
    interior: {
        label: 'Interior',
        skills: {
            knowledge: 'Knowledge',
            skills: 'Skills',
            liveFire1: 'Live Fire 1'
        }
    },
    fullService: {
        label: 'Full Service',
        skills: {
            knowledge: 'Knowledge',
            skills: 'Skills',
            communityService: 'Community Service',
            liveFire2: 'Live Fire 2',
            hazmatOps: 'HAZMAT Ops'
        }
    },
    teamLeader: {
        label: 'Team Leader',
        skills: {
            esm1: 'ESM 1',
            ics200: 'ICS 200',
            engineBoss: 'Engine Boss'
        }
    },
    fireOfficer1: {
        label: 'Fire Officer 1',
        shortLabel: 'FO 1',
        skills: {
            frontlineLeadership1: {
                label: 'Frontline Leadership1',
                width: 190
            },
            fireServiceAdmin1: {
                label: 'Fire Service Admin 1',
                width: 190
            },
            companyInspections: {
                label: 'Company Inspections',
                width: 190
            },
            instructor1: 'Instructor 1'
        }
    },
    fireOfficer2: {
        label: 'Fire Officer 2',
        shortLabel: 'FO 2',
        skills: {
            esm1: 'ESM 2',
            frontlineLeadership2: {
                label: 'Frontline Leadership 2',
                width: 190
            },
            fireServiceAdmin2: {
                label: 'Fire Service Admin 2',
                width: 190
            },
            fireCauseAndOrigin1: {
                label: 'Fire Cause & Origin 1',
                width: 190
            },
            ics300: 'ICS 300'
        }
    },
    fireOfficer3: {
        label: 'Fire Officer 3',
        shortLabel: 'FO 3',
        skills: {
            budgetManagement: {
                label: 'Bugdet Management',
                width: 190
            },
            organizationalLeadership1: {
                label: 'Organizational Leadership 1',
                width: 220
            },
            leadingPeople1: 'Leading People1',
            planningAndEvaluation1: {
                label: 'Planning & Evaluation 1',
                width: 190
            },
            ics400: 'ICS 400',
            informationOfficer: 'Information Officer'
        }
    },
    fireOfficer4: {
        label: 'Fire Officer 4',
        shortLabel: 'FO 4',
        skills: {
            businessOperations: {
                label: 'Business Operations',
                width: 190
            },
            organizationalLeadership2: {
                label: 'Organizational Leadership 2',
                width: 220
            },
            leadingPeople2: 'Leading People 2',
            planningAndEvaluation2: {
                label: 'Planning & Evaluation 2',
                width: 190
            }
        }
    },
    rescueTraining: {
        label: 'Rescue Training',
        width: 150,
        extra: true,
        skills: {
            lowAngle: 'Low Angle',
            highAngleOps: 'High Angle Ops',
            highAngleTech: {
                label: 'High Angle Technician',
                width: 190
            },
            hazmatTech: {
                label: 'HAZMAT Technician',
                width: 190
            },
            swiftWaterAwareness: {
                label: 'Swift Water Awareness',
                width: 190
            },
            swiftWaterOps: 'Swift Water Ops',
            swiftWaterTech: {
                label: 'Swift Water Technician',
                width: 190
            },
            confinedSpaceAwareness: {
                label: 'Confined Space Awareness',
                width: 220
            },
            confinedSpaceOps: 'Confined Space Ops',
            confinedSpaceTech: {
                label: 'Confined Space Technician',
                width: 220
            },
            forcibleEntryAwareness: {
                label: 'Forcible Entry Awareness',
                width: 190
            },
            forcibleEntryOps: 'Forcible Entry Ops',
            forcibleEntryTech: {
                label: 'Forcible Entry Technician',
                width: 190
            },
            largeAnimal: {
                label: 'Large Animal Rescue',
                width: 190
            },
            passengerVehicle: {
                label: 'Passenger Vehicle Rescue',
                width: 190
            }
        }
    },
    wildland: {
        label: 'Wildland',
        extra: true,
        skills: {
            engineBoss: {
                skill: 'teamLeader.engineBoss'
            },
            taskForceLeader: 'Task Force Leader',
            divisonSupervisor: 'Division Supervisor',
            s190: {
                label: 'S-190'
            },
            s290: {
                label: 'S-290'
            },
            spp115: 'WSPP-115',
            s185: 'S-185 (Entrapment)',
            s212: {
                label: 'S-212 (Communication)',
                width: 190
            }
        }
    },
    education: {
        label: 'Education',
        extra: true,
        skills: {
            fireServiceInstructor2: {
                label: 'Fire Service Instructor 2',
                width: 190
            },
            jibcEvaluator: {
                label: 'JIBC Evaluator',
                hasExpiry: true
            },
            fireAndLifeSafetyEducator: {
                label: 'Fire & Life Safety Educator',
                width: 190
            },
            firstResponderInstructor: {
                label: 'FR Instructor',
                width: 120
            },
            emrInstructor: {
                label: 'EMR Instructor',
                width: 190
            },
            sppWff1Instructor: {
                label: 'WSPP-WFF 1 Instructor',
                width: 190
            },
            EMR: 'EMR'
        }
    },
    eoc: {
        label: 'EOC',
        extra: true,
        skills: {
            ops: 'Ops',
            planning: 'Planning',
            logistics: 'Logistics',
            finance: 'Finance',
            informationOfficer: 'Information Officer',
            intro: 'EOC Intro',
            essentials: 'EOC Essentials',
        }
    },
    iso: {
        label: 'Incident Safety Officer',
        extra: true,
        width: 190,
        skills: {
            iso: {
                label: 'Incident Safety Officer',
                width: 190
            }
        }
    }
};

export const getSkillLabel = field => {
    if (!field) {
        return '';
    }

    const [, level, skill] = field.split('.');
    const levelLabel = get(Skills, `${level}.label`);
    const value = get(Skills, `${level}.skills.${skill}`);
    const {label: skillLabel, hasExpiry = false} = typeof value === 'object' ? value : {label: value};

    return {
        label: `${levelLabel} - ${skillLabel}`,
        levelLabel,
        skillLabel,
        hasExpiry
    };
}

export const SkillStages = {
    INCOMPLETE: {
        label: 'Incomplete',
        color: 'primary',
        icon: <DoNotDisturbIcon />
    },
    NEEDED: {
        label: 'Needed',
        color: 'needed',
        icon: <ErrorOutlineIcon />
    },
    SCHEDULED: {
        label: 'Scheduled',
        color: 'inProgress',
        icon: <CalendarMonthIcon />
    },
    INPROGRESS: {
        label: 'In Progress',
        color: 'inProgress',
        icon: <HourglassBottomIcon />
    },
    COMPLETE: {
        label: 'Complete',
        color: 'complete',
        icon: <CheckIcon />
    }
};

export const uploadSkillFile = async (uid, field, file) => {
    try {
        const token = await getAuth(firebaseApp).currentUser.getIdToken();

        const isDev = process.env.NODE_ENV === 'development';
        const base = isDev ? 'http://127.0.0.1:5001/jrfd-log/us-central1/' : 'https://us-central1-jrfd-log.cloudfunctions.net/';
        const url = `${base}uploadSkillFile`;

        const {data} = await axios.post(url, {
            uid,
            skill: field,
            file
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
            }
        });

        const {driveFileId} = data || {};
        return driveFileId;
    } catch(e) {
        throw new Error(e.message || 'Error uploading file');
    }
};