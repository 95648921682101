import React, {useState, useEffect} from 'react';
import {Backdrop, CircularProgress, ThemeProvider} from '@mui/material';
import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom';
import {getFirestore, onSnapshot, collection, query, getDocs, where} from 'firebase/firestore';
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import {SnackbarProvider} from 'notistack';
import {LicenseInfo} from '@mui/x-license-pro';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import StackdriverErrorReporter from 'stackdriver-errors-js';

import {UserContext} from './contexts/User';
import firebaseApp from './firebase.js';
import {hasFeature} from './features.js';

import theme from './style/Theme';

import AppContainer from './components/AppContainer';

import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Manage from './pages/Manage';

import Incidents from './pages/Incidents';
import Incident from './pages/Incident';
import ImportIncidents from './pages/incidents/Import';

import Members from './pages/Members';
import Member from './pages/Member';
import TrainingMatrix from './pages/TrainingMatrix';

import Apparatuses from './pages/Apparatuses';
import EditApparatus from './pages/EditApparatus';
import Apparatus from './pages/Apparatus';

import Checks from './pages/Checks';
import EditChecks from './pages/EditChecks';
import EditFullChecks from './pages/EditFullChecks';
import Check from './pages/Check';
import FullCheck from './pages/FullCheck';

import Calendar from './pages/Calendar';

import Training from './pages/Training';
import ImportTraining from './pages/training/Import';
import TrainingTypes from './pages/training/Types';

import Gear from './pages/Gear';
import Sheet from './pages/Sheet';

import Respond from './pages/respond';
import RespondMap from './pages/respond/Map';

import Activities from './pages/vs/Activities';

import {verifyOfficer} from './data/utils';

LicenseInfo.setLicenseKey('d0b27aac11bcb2423551b1b366d732a5Tz04NjMyMCxFPTE3NDE4ODQ5NzcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

export default function App() {
    const [initializing, setInitializing] = useState(true);
    const [currentUser, setCurrentUser] = useState();
    const [member, setMember] = useState();
    const db = getFirestore(firebaseApp);
    const auth = getAuth(firebaseApp);

    useEffect(() => {
        onAuthStateChanged(auth, async(user) => {
            if (user) {
                const {uid, email} = auth.currentUser;
                const ref = collection(db, 'users');
                const q = query(ref, where('email', '==', email));

                onSnapshot(q, async snapshot => {
                    const raw = await getDocs(q);

                    let data = {
                        id: uid,
                        uid,
                        role: 'RECRUIT',
                        isAdmin: false,
                        email
                    };

                    if (raw.size) {
                        let userData;

                        raw.forEach(doc => {
                            userData = doc.data();
                        });

                        const {dob: rawDob, ...rest} = userData;
                        const dob = rawDob ? rawDob.toDate() : null;

                        data = {
                            ...data,
                            ...rest,
                            dob,
                            isOfficer: verifyOfficer(userData),
                            email
                        };
                    }

                    setCurrentUser(data);
                    setMember(undefined);
                    setInitializing(false);
                });
            } else {
                setCurrentUser(undefined);
                setMember(undefined);
                setInitializing(false);
            }
        });
    }, [db, auth]);

    if (initializing) {
        return (
            <Backdrop invisible open>
                <CircularProgress color="primary" />
            </Backdrop>
        );
    }

    const reRouteIfAuthenticated = destination => {
        if (currentUser) {
            return <Navigate to="/" />;
        }
    
        return destination;
    };

    const reRouteIfUnauthenticated = destination => {
        if (!currentUser) {
            return <Navigate to="/login" />;
        }
    
        return destination;
    };

    const isOfficer = verifyOfficer(currentUser);
    const {isAdmin = false, role} = currentUser || {};
    const isStation = role === 'STATION';

    const isDev = process.env.NODE_ENV === 'development';
    if (!isDev) {
        const errorHandler = new StackdriverErrorReporter();
        errorHandler.start({
            key: 'AIzaSyA22NmhZl3BISoqlwku3kfMEtimoflM9m4',
            projectId: 'jrfd-log'
        });
    }

    return (
        <UserContext.Provider value={{currentUser, setCurrentUser, member, setMember}}>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-ca">
                    <SnackbarProvider maxSnack={3}>
                        <BrowserRouter>
                            <AppContainer>
                                <Routes>
                                    <Route path="/login" element={reRouteIfAuthenticated(<Login />)} />
                                    <Route path="/manage" element={reRouteIfAuthenticated(<Manage />)} />
                                    <Route path="*" element={reRouteIfUnauthenticated(<NotFound />)} />

                                    {currentUser && (
                                        <Route path="*">
                                            <Route index element={<Apparatuses />} />
                                            <Route path="profile" element={<Member isProfile />} />
                                            <Route path="profile/skills" element={<Member isProfile activeTab={1} />} />
                                            <Route path="profile/statistics" element={<Member isProfile activeTab={2} />} />
                                            <Route path="profile/transcript" element={<Member isProfile activeTab={3} />} />

                                            {isOfficer && (
                                                <Route path="profile/jibcConsent" element={<Member activeTab={4} />} />
                                            )}

                                            <Route index element={<Apparatuses />} />
                                            <Route path=":id" element={<Apparatus />} />
                                            <Route path=":id/edit" element={<EditApparatus />} />
                                            <Route path="new" element={<EditApparatus />} />

                                            <Route path=":id/:type" element={<Checks />} />
                                            <Route path=":id/weekly/:checkId" element={<Check />} />
                                            <Route path=":id/full/:checkId" element={<FullCheck />} />
                                            {isOfficer && (
                                                <>
                                                    <Route path=":id/weekly/edit" element={<EditChecks />} />
                                                    <Route path=":id/full/edit" element={<EditFullChecks />} />
                                                </>
                                            )}

                                            <Route path="training-matrix" element={<TrainingMatrix />} />

                                            <Route path="calendar" element={<Calendar />} />

                                            {isAdmin && hasFeature('ropes') && (
                                                <Route path="gear" element={<Gear type="ropes" />} />
                                            )}
                                            <Route path="ropes" element={<Sheet documentId="1ZEe1ff1HgaEf3MDIbQKFaWovStunJ9sL_3xPJNh7C0U" />} />
                                            
                                            {!isStation && hasFeature('incidents') && (
                                                <Route path="incidents">
                                                    <Route index element={<Incidents />} />

                                                    {isOfficer && (
                                                        <>
                                                            <Route path=":id" element={<Incident />} />
                                                            <Route path="import" element={<ImportIncidents />} />
                                                        </>
                                                    )}
                                                </Route>
                                            )}

                                            {isAdmin && hasFeature('training') && (
                                                <Route path="training">
                                                    <Route index element={<Training />} />
                                                    <Route path="import" element={<ImportTraining />} />
                                                    <Route path="types" element={<TrainingTypes />} />
                                                </Route>
                                            )}

                                            {isAdmin && hasFeature('vectorSolutionsSync') && (
                                                <Route path="vs">
                                                    <Route path="activities" element={<Activities />} />
                                                </Route>
                                            )}

                                            {isOfficer && (
                                                <>
                                                    <Route path="members">
                                                        <Route path=":id" element={<Member />} />
                                                        <Route path=":id/skills" element={<Member activeTab={1} />} />
                                                        <Route path=":id/statistics" element={<Member activeTab={2} />} />
                                                        <Route path=":id/transcript" element={<Member activeTab={3} />} />
                                                        <Route path=":id/jibcConsent" element={<Member activeTab={4} />} />
                                                        <Route index element={<Members />} />
                                                        <Route path="new" element={<Member />} />
                                                    </Route>
                                                </>
                                            )}

                                            <Route path="respond">
                                                <Route index element={<Respond />} />
                                                <Route path=":id" element={<RespondMap />} />
                                            </Route>
                                        </Route>
                                    )}
                                </Routes>
                            </AppContainer>
                        </BrowserRouter>
                    </SnackbarProvider>
                </LocalizationProvider>
            </ThemeProvider>
        </UserContext.Provider>
    );
}
