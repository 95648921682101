import {initializeApp} from 'firebase/app';
import {getFunctions, connectFunctionsEmulator} from 'firebase/functions';

const isDev = process.env.NODE_ENV === 'development';

const firebaseConfig = {
    apiKey: 'AIzaSyBJeMa6btN3_7IhaOjr2kzBEzc6t_YZoSg',
    authDomain: 'jrfd-log.firebaseapp.com',
    projectId: 'jrfd-log',
    storageBucket: 'jrfd-log.appspot.com',
    messagingSenderId: '33193956220',
    appId: '1:33193956220:web:46d085f276f7af93c9caf9'
};

if (process.env.REACT_APP_API_KEY) {
    firebaseConfig.apiKey = process.env.REACT_APP_API_KEY;
}

const app = initializeApp(firebaseConfig);

if (isDev) {
    const functions = getFunctions(app);
    connectFunctionsEmulator(functions, 'localhost', 5001);
}

export default app;