import React, {useState, useCallback, useEffect} from "react";
import {Box, Typography, Button} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import moment from 'moment';
import {getFirestore, doc, updateDoc, deleteField} from 'firebase/firestore';
import {getStorage, ref, uploadBytes, deleteObject, getDownloadURL} from 'firebase/storage';
import {useSnackbar} from 'notistack';

import firebaseApp from '../../firebase.js';

export default function User(props) {
    const {user = {}} = props;

    const {uid, jibcConsentDate: rawJibcConsentDate, jibcConsentUrl: rawJibcConsentUrl} = user || {};

    const [date, setDate] = useState(rawJibcConsentDate && rawJibcConsentDate.toDate());
    const [url, setUrl] = useState(rawJibcConsentUrl);

    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const db = getFirestore(firebaseApp);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const storage = getStorage(firebaseApp);

    useEffect(() => {
        setDate(rawJibcConsentDate && rawJibcConsentDate.toDate());
        setUrl(rawJibcConsentUrl);
    }, [rawJibcConsentDate, rawJibcConsentUrl]);

    const onDelete = useCallback(async() => {
        setDeleting(true);

        const reference = ref(storage, `/jibcConsentForms/${uid}.pdf`);
        await deleteObject(reference);

        const docRef = doc(db, 'users', uid);
        await updateDoc(docRef, {
            jibcConsentDate: deleteField(),
            jibcConsentUrl: deleteField()
        });
        
        setDate(null);
        setUrl(null);
        setDeleting(false);
    }, [storage, uid, db]);

    const handleDelete = useCallback(async file => {
        enqueueSnackbar('Are you sure you want to delete this consent form?', {
            variant: 'warning',
            action: key => {
                return (
                    <>
                        <Button onClick={() => {
                            closeSnackbar(key);
                            onDelete();
                        }}>
                            Delete
                        </Button>
                        <Button onClick={() => closeSnackbar(key)}>
                            Cancel
                        </Button>
                    </>
                );
            }
        });
    }, [enqueueSnackbar, closeSnackbar, onDelete]);

    const handleFileUpload = useCallback(async file => {
        if (!file || !uid) {
            return;
        }

        setLoading(true);

        try {
            const reference = ref(storage, `/jibcConsentForms/${uid}.pdf`);
            await uploadBytes(reference, file);
            
            const newUrl = await getDownloadURL(reference);
            const newDate = new Date();
            
            const docRef = doc(db, 'users', uid);
            await updateDoc(docRef, {
                jibcConsentDate: newDate,
                jibcConsentUrl: newUrl
            });

            setDate(newDate);
            setUrl(newUrl);

            enqueueSnackbar('JIBC Consent Form uploaded successfully!', {variant: 'success'});
        } catch(e) {
            enqueueSnackbar(e.message || 'Error uploading JIBC Consent Form', {variant: 'error'});
        }

        setLoading(false);
    }, [uid, storage, db, enqueueSnackbar]);

    return (
        <Box>
            <Box sx={{display: 'flex', alignItems: 'center', mt: 2, mb: url && 2}}>
                <Typography variant="caption" sx={{flex: 1}}>Last update: {date ? moment(date).format('LLL') : 'never'}</Typography>
                {url && <LoadingButton loading={deleting} sx={{mr: 1}} variant="outlined" component="label" onClick={handleDelete}>Delete</LoadingButton>}
                <LoadingButton loading={loading} variant="contained" component="label">
                    Upload JIBC Consent Form
                    <input
                        type="file"
                        hidden
                        accept="application/pdf"
                        onChange={e => {
                            const file = e.target.files[0];
                            handleFileUpload(file);
                        }}
                    />
                </LoadingButton>
            </Box>

            {url && (
                <embed src={url} style={{width: '100%', aspectRatio: '4/3', minHeight: 500}} />   
            )}
        </Box>
    );
}