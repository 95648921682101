import React, {useState, useEffect, useMemo, useCallback, useContext} from 'react';
import {Card, CardContent, Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField} from '@mui/material';
import {useSnackbar} from 'notistack';
import {getFirestore, collection, doc, getDocs, query, where, updateDoc, deleteDoc} from 'firebase/firestore';
import moment from 'moment';
import {useForm, useFormContext, FormProvider} from 'react-hook-form';
import {LoadingButton} from '@mui/lab';
import {useNavigate} from 'react-router-dom';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {getAuth} from 'firebase/auth';
import axios from 'axios';

import {useGridApiRef, GridActionsCellItem} from '@mui/x-data-grid-pro';

import {Sync as SyncIcon, Delete as DeleteIcon} from '@mui/icons-material';

import firebaseApp from '../firebase';
import {hasFeature} from '../features';

import {UserContext} from '../contexts/User';

import DatePickerField from '../form/DatePickerField.js';
import SelectField from '../form/SelectField.js';

import TrainingGrid from './training/Grid';
import Stats from './training/Stats';

import VSSync from '../dialogs/VSSync';

const ImportFromIAR = props => {
    const [importing, setImporting] = useState(false);
    const [open, setOpen] = useState(false);
    const [startDate, setStartDate] = useState(props.startDate || moment().subtract(1, 'month').toDate());
    const [endDate, setEndDate] = useState(new Date());
    const navigate = useNavigate();
    const functions = getFunctions(firebaseApp);
    const {enqueueSnackbar} = useSnackbar();
    const importTrainingFromIAR = httpsCallable(functions, 'importTrainingFromIAR');

    useEffect(() => {
        if (props.startDate) {
            setStartDate(props.startDate);
        }
    }, [props.startDate]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleImport = useCallback(async() => {
        setImporting(true);

        try {
            const {data} = await importTrainingFromIAR({startDate, endDate});
            const {training = []} = data;

            navigate('/training/import', {state: {
                training: training.map(training => {
                    const {date, suggestedTypes: types, suggestedInstructors: instructors, ...rest} = training;

                    return {
                        ...rest,
                        date: new Date(date),
                        types,
                        instructors
                    };
                })
            }});
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setImporting(false);
    }, [importTrainingFromIAR, enqueueSnackbar, navigate, startDate, endDate]);

    return (
        <>
            <LoadingButton loading={importing} variant="contained" sx={{ml: 1}} onClick={() => setOpen(true)}>
                Import latest from IAR
            </LoadingButton>

            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: e => {
                        e.preventDefault();

                        handleImport();
                    }
                }}
            >
                <DialogTitle>Import from IamResponding</DialogTitle>
                <DialogContent>
                    <DatePicker
                        label="Start Date"
                        inputFormat="DD/MM/yyyy"
                        value={startDate}
                        onChange={date => setStartDate(date && date.toDate())}
                        renderInput={params => (
                            <TextField
                                fullWidth 
                                name="startDate"
                                margin="dense"
                                {...params}
                            />
                        )}
                    />

                    <DatePicker
                        label="End Date"
                        inputFormat="DD/MM/yyyy"
                        value={endDate}
                        onChange={date => setEndDate(date && date.toDate())}
                        renderInput={params => (
                            <TextField
                                fullWidth 
                                name="endDate"
                                margin="dense"
                                {...params}
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={importing} onClick={handleClose}>Cancel</Button>
                    <LoadingButton loading={importing} type="submit">Start Import</LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

const Filter = ({loading, onSubmit}) => {
    const {handleSubmit, watch, reset} = useFormContext();
    const dateType = watch('dateType');

    useEffect(() => {
        if (dateType === 'thisYear') {
            const startDate = moment().startOf('year').toDate();
            const endDate = moment().endOf('year').toDate();

            reset({dateType, startDate, endDate});
        } else if (dateType === 'lastYear') {
            const startDate = moment().subtract(1, 'year').startOf('year').toDate();
            const endDate = moment().subtract(1, 'year').endOf('year').toDate();

            reset({dateType, startDate, endDate});
        } else if (dateType === 'thisQuarter') {
            const startDate = moment().startOf('quarter').toDate();
            const endDate = moment().endOf('quarter').toDate();

            reset({dateType, startDate, endDate});
        } else if (dateType === 'lastQuarter') {
            const startDate = moment().startOf('quarter').subtract(1, 'quarter').toDate();
            const endDate = moment().endOf('quarter').subtract(1, 'quarter').toDate();

            reset({dateType, startDate, endDate});
        } else if (dateType === 'thisMonth') {
            const startDate = moment().startOf('month').toDate();
            const endDate = moment().endOf('month').toDate();

            reset({dateType, startDate, endDate});
        }
    }, [dateType, reset]);

    return (
        <Card sx={{mb: 2}}>
            <CardContent sx={{p: 2}}>
                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'flex-end', pt: 1}} component="form" onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={{flex: 1}}>
                        <SelectField
                            sx={{mr: 1, width: 250}}
                            label="Search Queries"
                            name="dateType"
                            disabled={loading}
                            options={[
                                {label: 'This Year', value: 'thisYear'},
                                {label: 'This Quarter', value: 'thisQuarter'},
                                {label: 'This Month', value: 'thisMonth'},
                                {label: 'Last Year', value: 'lastYear'},
                                {label: 'Last Quarter', value: 'lastQuarter'}
                            ]}
                        />

                        <DatePickerField
                            sx={{mr: 1, width: 250}}
                            label="Start Date"
                            name="startDate"
                            disabled={loading}
                        />
                        <DatePickerField
                            sx={{mr: 1, width: 250}}
                            label="End Date"
                            name="endDate"
                            disabled={loading}
                        />
                    </Box>

                    <LoadingButton
                        type="submit"
                        variant="contained"
                        onClick={handleSubmit(onSubmit)}
                        disabled={loading}
                        loading={loading}
                    >
                        Search
                    </LoadingButton>
                </Box>
            </CardContent>
        </Card>
    );
};

const Training = () => {
    const [loading, setLoading] = useState(false);
    const [exporting, setExporting] = useState(false);
    const [training, setTraining] = useState([]);
    const [users, setUsers] = useState([]);
    const db = getFirestore(firebaseApp);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const apiRef = useGridApiRef();
    const {currentUser} = useContext(UserContext);
    const {isAdmin} = currentUser;
    const [dialogOpen, setDialogOpen] = useState(false);
    const [latestTrainingDate, setLatestTrainingDate] = useState(null);

    const defaultValues = useMemo(() => ({
        dateType: 'thisYear',
        startDate: moment().startOf('year').toDate(),
        endDate: moment().toDate()
    }));

    const methods = useForm({
        defaultValues,
        mode: 'onChange'
    });

    const {watch} = methods;
    const startDate = watch('startDate');
    const endDate = watch('endDate');

    const onSubmit = useCallback(async data => {
        let {startDate, endDate} = data;
        if (!startDate || !endDate) {
            return;
        }

        async function fetch() {
            setLoading(true);

            try {
                startDate = moment(startDate).startOf('day').toDate();
                endDate = moment(endDate).endOf('day').toDate();

                const ref = collection(db, 'training');
                const q = query(ref, where('date', '>=', startDate), where('date', '<=', endDate));
                const raw = await getDocs(q);
                let training = [];

                raw.forEach(doc => {
                    const {date, ...rest} = doc.data();

                    training.push({
                        id: doc.id,
                        uid: doc.id,
                        ...rest,
                        date: date.toDate()
                    });
                });

                const orderedTraining = training.sort((a, b) => {
                    return moment(b.date).diff(moment(a.date));
                });

                if (orderedTraining.length) {
                    setLatestTrainingDate(orderedTraining[0].date);
                }

                setTraining(training);
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }

            setLoading(false);
        }

        fetch();
    }, [enqueueSnackbar, db]);

    useEffect(() => {
        onSubmit(defaultValues);
    }, []);

    const handleProcessRowUpdate = useCallback(async(updatedRow, originalRow) => {
        if (!isAdmin) {
            return;
        }

        const {uid, types = [], instructors = []} = updatedRow;
        
        const ref = doc(db, 'training', uid);
        await updateDoc(ref, {
            types: types.map(type => type.id),
            instructors: instructors.map(instructor => instructor.id)
        });

        setTraining(training.map(row => {
            if (row.uniqueId === originalRow.uniqueId) {
                return {
                    ...row,
                    types: types.map(type => type.id),
                    instructors: instructors.map(instructor => instructor.id)
                };
            }

            return row;
        }));

        return updatedRow;
    }, [isAdmin, training, db]);

    const handleSync = useCallback(async(params) => {
        const {row} = params;
        
        setDialogOpen({training: row});
    }, []);

    const handleSyncClose = useCallback(async newTraining => {
        setDialogOpen(false);

        if (newTraining) {
            setTraining(training.map(training => {
                if (training.uid === newTraining.uid) {
                    return {
                        ...training,
                        ...newTraining
                    };
                }

                return training;
            }));
        }
    }, [training]);

    const handleExport = useCallback(async() => {
        setExporting(true);

        const token = await getAuth(firebaseApp).currentUser.getIdToken();
        const isDev = process.env.NODE_ENV === 'development';
        const base = isDev ? 'http://127.0.0.1:5001/jrfd-log/us-central1/' : 'https://us-central1-jrfd-log.cloudfunctions.net/';

        try {
            const response = await axios({
                url: `${base}exportTraining`,
                method: 'POST',
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: {
                    startDate,
                    endDate
                }
            });

            const start = moment(startDate).format('YYYY-MM-DD');
            const end = moment(endDate).format('YYYY-MM-DD');

            const href = URL.createObjectURL(response.data);

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `Training - ${start} to ${end}.xlsx`);
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setExporting(false);
    }, [startDate, endDate, enqueueSnackbar]);

    const handleDelete = useCallback(async(params) => {
        const {row} = params;
        const {uid} = row;
        
        const onDelete = async() => {
            try {
                const ref = doc(db, 'training', uid);
                await deleteDoc(ref);

                setTraining(training.filter(training => training.uid !== uid));
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }
        };

        enqueueSnackbar('Are you sure you want to delete this? This cannot be undone', {
            variant: 'warning',
            action: key => {
                return (
                    <>
                        <Button onClick={() => {
                            closeSnackbar(key);
                            onDelete();
                        }}>
                            Delete
                        </Button>
                        <Button onClick={() => closeSnackbar(key)}>
                            Cancel
                        </Button>
                    </>
                );
            }
        });
    }, [training, closeSnackbar, db, enqueueSnackbar]);

    const columns = [];
    
    if (isAdmin) {
        columns.push({
            field: 'actions',
            type: 'actions',
            getActions: params => [
                <GridActionsCellItem icon={<SyncIcon />} onClick={() => handleSync(params)} label="Sync with VS" />,
                <GridActionsCellItem icon={<DeleteIcon />} onClick={() => handleDelete(params)} label="Delete" />
            ]
        });
    }

    return (
        <FormProvider {...methods}>
            {isAdmin && (
                <Box sx={{mb: 2, display: 'flex', justifyContent: 'flex-end'}}>
                    <ImportFromIAR startDate={latestTrainingDate} />
                </Box>
            )}

            {isAdmin && !!dialogOpen && (
                <VSSync open={!!dialogOpen} {...dialogOpen} onClose={handleSyncClose} />
            )}

            <Filter loading={loading} onSubmit={onSubmit} />

            <Box sx={{opacity: loading ? .5 : 1}}>
                {(hasFeature('trainingStatistics') && training.length > 0) && <Stats training={training} users={users} />}

                <TrainingGrid
                    apiRef={apiRef}
                    columns={columns}
                    stateId="training"
                    training={training}
                    loading={loading}
                    editMode={isAdmin && 'row'}
                    processRowUpdate={handleProcessRowUpdate}
                    onProcessRowUpdateError={e => console.warn(e)}
                />
            </Box>

            {isAdmin && (
                <Box sx={{mt: 2, display: 'flex', justifyContent: 'flex-end'}}>
                    <LoadingButton disabled={loading || !training.length} loading={exporting} variant="contained" sx={{ml: 1}} onClick={handleExport}>
                        Export Attendance
                    </LoadingButton>
                </Box>
            )}
        </FormProvider>
    );
};

export default Training;