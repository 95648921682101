import React, {useState, useEffect, useContext} from 'react';
import {TextField, Autocomplete} from '@mui/material';
import {Controller} from 'react-hook-form';
import {getFirestore, collection, getDocs, query, where, orderBy} from 'firebase/firestore';
import {useSnackbar} from 'notistack';

import {UserContext} from '../contexts/User';
import firebaseApp from '../firebase';

export default function ControlledAutocompleteField(props) {
    const {name, label, rules, limitToStation, ...rest} = props;

    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState([]);
    const {currentUser} = useContext(UserContext);
    const {isAdmin} = currentUser;

    const db = getFirestore(firebaseApp);
    const {enqueueSnackbar} = useSnackbar();
    
    useEffect(() => {
        let isSubscribed = true;

        async function fetch() {
            try {
                const ref = collection(db, 'users');
                let q = query(ref, orderBy('lastName', 'asc'));

                if (limitToStation && !isAdmin) {
                    q = query(ref, where('station', '==', currentUser.station));
                }

                const raw = await getDocs(q);
                let docs = [];

                raw.forEach(doc => {
                    const data = doc.data();

                    docs.push({
                        id: doc.id,
                        uid: doc.id,
                        ...data
                    });
                });

                docs = docs.filter(doc => doc.deactivated !== true);

                if (isSubscribed) {
                    setOptions(docs.filter(doc => {
                        return doc.role !== 'STATION';
                    }).map(doc => {
                        const {id, fullName} = doc;

                        return {
                            value: id,
                            label: fullName
                        };
                    }));
                }
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }

            if (isSubscribed) {
                setLoading(false);
            }
        }

        fetch();
        
        return () => isSubscribed = false;
    }, [enqueueSnackbar, db, currentUser, limitToStation, isAdmin]);

    return (
        <Controller
            name={name}
            rules={rules}
            render={({field, fieldState: {error}}) => {
                const {value, onChange, ...restField} = field;

                return (
                    <Autocomplete
                        {...rest}
                        {...restField}
                        loading={loading}
                        multiple
                        value={value ? value.map(item => {
                            return options.find(option => option.value === item);
                        }) : []}
                        options={options}
                        onChange={(e, newValue) => {
                            onChange(newValue.map(item => item.value));
                        }}
                        getOptionLabel={option => option ? option.label : ''}
                        isOptionEqualToValue={(option, value) => {
                            return value && option.value === value.value;
                        }}
                        filterSelectedOptions
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={label}
                            />
                        )}
                    />
                );
            }}
        />
    );
};
