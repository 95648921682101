import React, {useState, useEffect, useContext} from "react";
import {Grid, Box, Backdrop, CircularProgress, Button, Divider, Typography, Chip, Card, CardMedia} from '@mui/material';
import {getFirestore, doc, getDoc} from 'firebase/firestore';
import {useParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';

import {UserContext} from '../contexts/User';
import firebaseApp from '../firebase';

export default function Apparatus(props) {
    const {id: uid} = useParams();
    const isNew = !uid;

    const [loading, setLoading] = useState(!isNew);
    const [record, setRecord] = useState(null);
    const db = getFirestore(firebaseApp);
    const navigate = useNavigate();
    const {currentUser} = useContext(UserContext);

    const {isOfficer} = currentUser;

    useEffect(() => {
        let isSubscribed = true;

        const fetch = async() => {
            if (uid) {
                const ref = doc(db, 'apparatus', uid);
                const raw = await getDoc(ref);
                
                if (isSubscribed) {
                    if (!raw.exists()) {
                        navigate('/');
                    }

                    setRecord({
                        id: uid,
                        uid,
                        ...raw.data()
                    });

                    setLoading(false);
                }
            }
        };

        fetch();

        return () => isSubscribed = false;
    }, [db, uid, navigate]);

    useEffect(() => {
        try {
            const {tag} = record;
            document.title = `${tag} | JRFD`;
        } catch(e) {
            document.title = 'JRFD';
        }
    }, [record]);

    if (loading) {
        return (
            <Backdrop invisible open>
                <CircularProgress color="primary" />
            </Backdrop>
        );
    }

    const colorMap = {
        red: '#ce1618',
        yellow: '#fdd835'
    };

    const colorTextMap = {
        red: '#fff'
    };

    const colorVariantMap = {
        white: 'outlined'
    };

    const {tag, color, imageUrl} = record || {};

    return (
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Box sx={{display: 'flex', pb: 1, alignItems: 'center'}}>
                <Typography variant="h5" component="h1" sx={{flex: 1}}>
                    {tag}
                </Typography>
                {color && (
                    <Chip
                        label={color.toUpperCase()}
                        sx={{
                            background: colorMap[color] || color,
                            color: colorTextMap[color] || null
                        }}
                        variant={colorVariantMap[color] || 'filled'}
                    />
                )}
            </Box>

            <Divider sx={{mb: 2}} />

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Card>
                        {imageUrl && (
                            <CardMedia
                                component="img"
                                height="40%"
                                image={imageUrl}
                                alt={tag}
                            />
                        )}
                    </Card>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <>
                            <Button variant="outlined" onClick={() => navigate(`/${uid}/weekly`)}>Weekly Truck Check</Button>
                            <Button sx={{mt: 2}} variant="outlined" disabled onClick={() => navigate(`/${uid}/full`)}>Full Truck Check</Button>
                        </>

                        {isOfficer && (
                            <>
                                <Button sx={{mt: 2}} variant="contained" onClick={() => navigate(`/${uid}/edit`)}>Edit Apparatus</Button>
                            </>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}