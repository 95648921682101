import React, {useState, useCallback, useEffect} from "react";
import {Box, Link, Typography, Card, CardContent, CardMedia, Skeleton} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import moment from 'moment';
import {useSnackbar} from 'notistack';
import {getAuth} from 'firebase/auth';
import axios from 'axios';

import firebaseApp from '../../firebase.js';

export default function User(props) {
    const {user = {}} = props;

    const {uid, transcriptDate: rawTranscriptDate, transcriptDriveFileId} = user || {};

    const [transcriptDate, setTranscriptDate] = useState(rawTranscriptDate && rawTranscriptDate.toDate());
    const [transcriptUrl, setTranscriptUrl] = useState();

    const [loading, setLoading] = useState(false);
    const [loadingFile, setLoadingFile] = useState(false);
    const [iframeSrc, setIframeSrc] = useState(null);
    const {enqueueSnackbar} = useSnackbar();

    const getFileDetails = useCallback(async(uid) => {
        const token = await getAuth(firebaseApp).currentUser.getIdToken();
        const isDev = process.env.NODE_ENV === 'development';
        const base = isDev ? 'http://127.0.0.1:5001/jrfd-log/us-central1/' : 'https://us-central1-jrfd-log.cloudfunctions.net/';

        try {
            const response = await axios({
                url: `${base}downloadTranscript`,
                method: 'POST',
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: {
                    uid
                }
            });

            const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
            setIframeSrc(url);

            return url;
        } catch(e) {
            setTranscriptUrl(null);
        }
    }, [setTranscriptUrl]);

    useEffect(() => {
        if (!iframeSrc && transcriptDriveFileId) {
            const fn = async() => {
                setLoadingFile(true);
                await getFileDetails(uid);
                setLoadingFile(false);
            };

            fn();
        }
    }, [uid, iframeSrc, transcriptDriveFileId, getFileDetails]);

    const handleFileUpload = useCallback(async file => {
        if (!file || !uid) {
            return;
        }

        setLoading(true);
        
        try {
            const token = await getAuth(firebaseApp).currentUser.getIdToken();

            const isDev = process.env.NODE_ENV === 'development';
            const base = isDev ? 'http://127.0.0.1:5001/jrfd-log/us-central1/' : 'https://us-central1-jrfd-log.cloudfunctions.net/';
            const url = `${base}uploadTranscript`;

            const {data} = await axios.post(url, {
                uid,
                file
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                     'Content-Type': 'multipart/form-data'
                }
            });

            const {driveFileId} = data || {};
            if (driveFileId) {
                setTranscriptDate(new Date());

                setLoadingFile(true);
                await getFileDetails(uid);
                setLoadingFile(false);
            }

            setLoading(false);
            
            enqueueSnackbar('File uploaded successfully!', {variant: 'success'});
        } catch(e) {
            enqueueSnackbar(e.message || 'Error uploading transcript', {variant: 'error'});
        }


        setLoading(false);
    }, [uid, enqueueSnackbar, getFileDetails]);

    return (
        <Box>
            <Card variant="outlined" sx={{background: '#f5f5f5'}}>
                <CardContent sx={{pb: '16px !important'}}>
                    <Typography component="ol" variant="subtitle2" sx={{flex: 1, paddingInlineStart: 2}}>
                        <li>Go to  <Link href="https://jibc-selfservice.colleagueservices.ca/Student/Student/Transcripts" target="_blank">https://jibc-selfservice.colleagueservices.ca/Student/Student/Transcripts</Link></li>
                        <li>Log in with JIBC your student number & password</li>
                        <li>
                            Click on the link that looks like this:
                            <br />
                            <img src="/download-transcript.png" alt="Download JIBC Transcript" style={{width: 301, borderRadius: 10}} />
                        </li>
                        <li>Your transcript will save to your download folder</li>
                        <li>Upload the transcript PDF file using the button below</li>
                    </Typography>
                </CardContent>
            </Card>

            <Box sx={{display: 'flex', alignItems: 'center', mt: 2, mb: transcriptUrl && 2}}>
                <Typography variant="caption" sx={{flex: 1}}>Last update: {transcriptDate ? moment(transcriptDate).format('LLL') : 'never'}</Typography>
                <LoadingButton loading={loading} variant="contained" component="label">
                    Upload JIBC Transcript
                    <input
                        type="file"
                        hidden
                        accept="application/pdf"
                        onChange={e => {
                            const file = e.target.files[0];
                            handleFileUpload(file);
                        }}
                    />
                </LoadingButton>
            </Box>

            {(iframeSrc || loadingFile || transcriptDriveFileId) && (
                <Card variant="outlined" sx={{mt: 2}}>
                    {loadingFile ? (
                        <Skeleton sx={{height: 400}} animation="wave" variant="rectangular" />
                    ) : (
                        <CardMedia component="iframe" src={iframeSrc} sx={{border: 0, aspectRatio: '4/3', minHeight: 500}} />
                    )}
                </Card>
            )}
        </Box>
    );
}