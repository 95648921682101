import React, {useState, useCallback, useContext} from 'react';
import {Box, Button, TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useTheme} from '@mui/material/styles';
import {FireTruck as FireTruckIcon, Code as CodeIcon} from '@mui/icons-material';
import ReactJson from '@microlink/react-json-view';
import {isNil, sortBy} from 'lodash';
import {useSnackbar} from 'notistack';
import {getFirestore, doc, updateDoc} from 'firebase/firestore';

import firebaseApp from '../../firebase.js';

import {UserContext} from '../../contexts/User';

import MemberHours from '../../components/members/Hours';

import Map from './Map';

const Detail = function({row}) {
    const {uid, dispatch, members, vehicles, durations, ...rest} = row;
    const {salary, salaryOverrides: rawSalaryOverrides = {}} = durations || {};
    const {notes = ['N/A']} = dispatch || {};
    const theme = useTheme();
    const {currentUser} = useContext(UserContext);
    const [saving, setSaving] = useState(false);
    const [hasSalaryOverrideChanges, setHasSalaryOverrideChanges] = useState(false);
    const [salaryOverrides, setSalaryOverrides] = useState(rawSalaryOverrides || {});
    const db = getFirestore(firebaseApp);
    const {enqueueSnackbar} = useSnackbar();

    const {isAdmin = false} = currentUser;

    const sortedMembers = sortBy(members, ['station', 'lastName']);

    const debugData = {
        ...rest,
        durations,
        members: sortedMembers.map(member => member.id),
        vehicles: vehicles.map(vehicle => vehicle.id)
    };

    const handleHourChange = useCallback((e, member) => {
        setHasSalaryOverrideChanges(true);

        setSalaryOverrides(prev => {
            const existingValue = prev[member.id];
            const rawValue = e.target.value;
            let newValue =rawValue === '' ? undefined : parseFloat(rawValue);

            if (isNil(newValue)) {
                const newData = {...prev};
                delete newData[member.id];
                return newData;
            }

            if (isNil(existingValue)) {
                newValue += parseFloat(salary);
            }
            
            return {
                ...prev,
                [member.id]: newValue
            };
        });
    }, [salary]);
    
    const handleSaveSalaryOverrides = useCallback(async () => {
        if (!hasSalaryOverrideChanges) {
            return;
        }

        try {
            setSaving(true);

            const ref = doc(db, 'incidents', uid);
            await updateDoc(ref, {
                'durations.salaryOverrides': salaryOverrides
            });

            enqueueSnackbar('Saved incident', {variant: 'success'});

            setHasSalaryOverrideChanges(false);
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setSaving(false);
    }, [db, hasSalaryOverrideChanges, salaryOverrides, uid, enqueueSnackbar]);
    
    const handleResetSalaryOverrides = useCallback(() => {
        setHasSalaryOverrideChanges(false);
        
        setSalaryOverrides(rawSalaryOverrides);
    }, [rawSalaryOverrides]);

    return (
        <Box sx={{p: 2, backgroundColor: theme.palette.grey[50], display: 'flex', flexDirection: 'column'}}>
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
                <Box sx={{flex: 1, mr: 1}}>
                    <Map incident={row} />

                    <TableContainer elevation={1} component={Paper} sx={{mr: 1, mt: 1, flex: 2}}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Dispatch Notes</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {notes.map((row, index) => (
                                <TableRow
                                    key={`detail-note-${index}`}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell scope="row">
                                        {row}
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                <Box sx={{flex: 1}}>
                    <TableContainer component={Paper} sx={{mb: 1}}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <FireTruckIcon sx={{fontSize: 20}} />
                                        <Box sx={{ml: 1}}>Apparatus</Box>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {vehicles.length ? vehicles.map((row, index) => {
                                    const {type, station} = row || {};

                                    return (
                                        <TableRow
                                            key={`detail-member-${index}`}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell scope="row">
                                                {type} {station}
                                            </TableCell>
                                        </TableRow>
                                    );
                                }) : (
                                    <TableRow>
                                        <TableCell sx={{textAlign: 'center'}}>
                                            No apparatus assigned
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <MemberHours
                        members={sortedMembers}
                        defaultSalary={salary}
                        salaryOverrides={salaryOverrides}
                        handleHourChange={handleHourChange}
                    />
                    
                    {hasSalaryOverrideChanges && (
                        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', mt: 1, mb: 1}}>
                            <Button disabled={saving} variant="outlined" size="small" sx={{mr: 1}} onClick={handleResetSalaryOverrides}>Reset Hour Changes</Button>
                            <LoadingButton loading={saving} variant="contained" size="small" onClick={handleSaveSalaryOverrides}>Save Hour Changes</LoadingButton>
                        </Box>
                    )}
                </Box>
            </Box>

            {isAdmin && (
                <TableContainer component={Paper} sx={{mt: 1}}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <CodeIcon sx={{fontSize: 20}} />
                                    <Box sx={{ml: 1}}>Raw Data</Box>
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <ReactJson collapsed src={debugData} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>       
            )}
        </Box>
    );
};

export default Detail;