import React, {useState, useEffect, useCallback, useContext} from 'react';
import {Box, Grid, Typography, Card, CardContent, Button} from '@mui/material';
import {DataGridPro} from '@mui/x-data-grid-pro';
import {useParams} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {LoadingButton, Timeline, TimelineItem, TimelineDot, TimelineSeparator, TimelineConnector, TimelineOppositeContent} from '@mui/lab';
import moment from 'moment';
import {arrayMoveImmutable} from 'array-move';
import {getFirestore, collection, getDocs, query, where, orderBy, addDoc, limit} from 'firebase/firestore';
import AddIcon from '@mui/icons-material/Add';
import HistoryIcon from '@mui/icons-material/History';
import SaveIcon from '@mui/icons-material/Save';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

import {UserContext} from '../contexts/User';
import firebaseApp from '../firebase';

import {populateUsers} from '../data/utils';

import UserAvatar from '../components/UserAvatar';

const EditChecks = () => {
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [editing, setEditing] = useState(false);
    const [records, setRecords] = useState([]);
    const [activeRecord, setActiveRecord] = useState(null);
    const [checks, setChecks] = useState([]);
    const db = getFirestore(firebaseApp);
    const {enqueueSnackbar} = useSnackbar();
    const {currentUser} = useContext(UserContext);
    const {id: uid} = useParams();

    let isSubscribed = true;

    const fetch = useCallback(async() => {
        try {
            const ref = collection(db, 'checks');
            const q = query(ref, where('apparatus', '==', uid), where('type', '==', 'weekly'), orderBy('createdAt', 'desc'), limit(25));
            const raw = await getDocs(q);
            let docs = [];

            raw.forEach(doc => {
                const data = doc.data();

                docs.push({
                    id: doc.id,
                    uid: doc.id,
                    ...data
                });
            });

            docs = await populateUsers(db, docs);

            if (isSubscribed) {
                const [latestDoc] = docs.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());

                setRecords(docs);
                setActiveRecord(latestDoc);
            }
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
            console.warn(e);
        }

        if (isSubscribed) {
            setLoading(false);
        }
    }, [enqueueSnackbar, db, uid, isSubscribed]);

    useEffect(() => {
        fetch();
        
        return () => isSubscribed = false;
    }, [enqueueSnackbar, db, uid]);

    useEffect(() => {
        if (activeRecord) {
            const {checks} = activeRecord;
            setChecks(checks);
        } else {
            setChecks([]);
        }
    }, [activeRecord]);

    const columns = [
        {
            field: 'text',
            headerName: 'Item to Check',
            flex: 1,
            sortable: false,
            editable: editing
        }
    ];

    if (editing) {
        columns.push({
            field: 'delete',
            headerName: '',
            type: 'actions',
            editable: false,
            renderCell: params => {
                const {id} = params;

                return (
                    <LoadingButton
                        color="primary"
                        size="small"
                        onClick={() => handleRowRemove(id)}
                    >
                        Delete
                    </LoadingButton>
                );
            },
            disableClickEventBubbling: true   
        });
    }

    const handleRowUpdate = useCallback(async(data, before) => {
        const {id, ...rest} = data;
        const newChecks = [...checks];
        newChecks.splice(id, 1, rest);
        setChecks(newChecks);

        return data;
    }, [checks]);
    
    const handleRowAdd = useCallback(async() => {
        const newChecks = checks ? [...checks] : [];
        newChecks.push({text: ''});
        setChecks(newChecks);

        // gridRef.current.startCellEditMode({id: newChecks.length - 1, field: 'text'});
    }, [checks]);

    const handleRowRemove = useCallback(async(index) => {
        const newChecks = checks ? [...checks] : [];
        newChecks.splice(index, 1);
        setChecks(newChecks);
    }, [checks]);

    const handleRowOrderChange = useCallback(change => {
        const {oldIndex, targetIndex} = change;
        const newChecks = arrayMoveImmutable(checks, oldIndex, targetIndex);
        setChecks(newChecks);
    }, [checks]);

    const handleSave = useCallback(async() => {
        try {
            setSaving(true);

            const docRef = collection(db, 'checks');
            await addDoc(docRef, {
                apparatus: uid,
                type: 'weekly',
                checks,
                createdAt: new Date(),
                user: currentUser.uid
            });

            setSaving(false);
            setEditing(false);

            await fetch();
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
            console.warn(e);

            setSaving(false);
        }
    }, [db, checks, currentUser, enqueueSnackbar, uid, fetch]);

    const handleCancel = useCallback(async() => {
        setEditing(false);

        setActiveRecord({
            ...activeRecord
        });
    }, [activeRecord]);

    return <>
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <Card variant="outlined">
                    <CardContent sx={{pb: 0}}>
                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                            <Typography sx={{flex: 1}} variant="h6">History</Typography>
                        </Box>
                        {records.length ? (
                            <Timeline position="left" sx={{pb: 0}}>
                                {records.map((record, index) => {
                                    const {user, createdAt} = record;
                                    const {fullName} = user || {};
                                    const isLast = index < (records.length - 1);
                                    const isActive = record === activeRecord;
                                    
                                    return (
                                        <TimelineItem sx={{opacity: isActive ? 1 : 0.6}} key={index} onClick={() => !editing && setActiveRecord(record)}>
                                            <TimelineOppositeContent sx={{pt: 0}}>
                                                <Typography variant="body1">
                                                    {moment(createdAt.toDate()).fromNow()}
                                                </Typography>
                                                <Box sx={{display: 'flex'}}>
                                                    <UserAvatar sx={{width: 20, height: 20, mr: 1}} user={user} />
                                                    <Typography variant="body2" color="text.secondary">
                                                        {fullName}
                                                    </Typography>
                                                </Box>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator>
                                                <TimelineDot sx={{width: 18, height: 18}} color={isActive ? 'primary' : 'grey'} variant={isActive ? 'filled' : 'outlined'} />
                                                {isLast && <TimelineConnector />}
                                            </TimelineSeparator>
                                        </TimelineItem>
                                    );
                                })}
                            </Timeline>
                        ) : (
                            <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', mt: 2}}>
                                <Typography variant="body2">No history</Typography>
                            </Box>
                        )}
                    </CardContent>
                </Card>
            </Grid>
            
            <Grid item xs={12} md={8}>
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', mb: 2}}>
                    {editing ? (
                        <>
                            <Button startIcon={<DoDisturbIcon />} size="small" sx={{mr: 1}} onClick={handleCancel}>Cancel</Button>
                            <LoadingButton loading={saving} size="small" startIcon={<SaveIcon />} variant="contained" onClick={handleSave}>Save Version</LoadingButton>
                        </>
                    ) : (
                        <Button startIcon={<HistoryIcon />} size="small" variant="contained" onClick={() => setEditing(true)}>Add Version</Button>
                    )}
                </Box>

                <Box style={{display: 'flex'}}>
                    <DataGridPro
                        rowReordering={editing}
                        onRowOrderChange={handleRowOrderChange}
                        hideFooter
                        loading={loading}
                        autoHeight
                        rows={(checks || []).map((check, id) => ({id, ...check}))}
                        columns={columns}
                        editMode="row"
                        disableRowSelectionOnClick
                        disableColumnFilter
                        disableColumnSelector
                        disableColumnMenu
                        processRowUpdate={handleRowUpdate}
                        experimentalFeatures={{newEditingApi: true}}
                    />
                </Box>
            </Grid>
        </Grid>
        
        {editing && (
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                <LoadingButton
                    variant="contained"
                    sx={{mt: 2}}
                    onClick={handleRowAdd}
                    disabled={saving || loading}
                    loading={saving}
                    startIcon={<AddIcon />}
                >
                    Add New Row
                </LoadingButton>
            </Box>
        )}
    </>;
};

export default EditChecks;