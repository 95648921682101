import React, {useEffect, useState} from 'react';
import {Paper, Box} from '@mui/material';
import {GoogleMap, OverlayView, Marker, useJsApiLoader} from '@react-google-maps/api';
import {useFormContext} from 'react-hook-form';
import {throttle} from 'lodash';

import {colorForIncidentType} from '../../data/utils';

const googleMapsLibraries = ['places'];

const DraggableMarker = props => {
    const {setValue} = useFormContext();

    const handleDrag = throttle(e => {
        const {latLng} = e;
        const {lat, lng} = {
            lat: latLng.lat(),
            lng: latLng.lng()
        };

        setValue('location.latitude', lat, {shouldDirty: true});
        setValue('location.longitude', lng, {shouldDirty: true});
    }, 100);

    return (
        <Marker
            draggable={true}
            onDrag={handleDrag}
            {...props}
        />
    );
};

export default function Map(props) {
    const {incident, editable = false, sx, mapContainerStyle} = props;
    const {type, location} = incident;
    const color = colorForIncidentType(type);
    const [position, setPosition] = useState();

    useEffect(() => {
        const {latitude, longitude} = location || {};
        if (!latitude || !longitude) {
            setPosition(null);
            return;
        }

        setPosition({
            lat: latitude,
            lng: longitude
        });
    }, [location]);

    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyCCI_7230YkA7R8LJXiqrg6_zVlfy9eSfk',
        libraries: googleMapsLibraries
    });

    if (!isLoaded || !position) {
        return;
    }

    console.log({position})

    const marker = editable ? (
        <DraggableMarker position={position} />
    ) : (
        <OverlayView position={position} mapPaneName={OverlayView.OVERLAY_LAYER}>
            <Box sx={{backgroundColor: color, width: 15, height: 15, borderRadius: 7}} />
        </OverlayView>
    );

    return (
        <Paper sx={{
            '.gm-style-pbc': {
                opacity: '0 !important'
            }, ...sx}}
        >
            <GoogleMap
                mapContainerStyle={{width: '100%', height: 150, ...mapContainerStyle}}
                center={position}
                zoom={12}
            >
                {marker}
            </GoogleMap>
        </Paper>
    );
};