import React, {useRef, useContext, useLayoutEffect, useEffect, useState, useCallback} from 'react';
import moment from 'moment';

import {DataGridPro, useGridApiContext} from '@mui/x-data-grid-pro';
import {Chip, Box, Typography, Autocomplete, TextField} from '@mui/material';
import {getFirestore, collection} from 'firebase/firestore';
import {Check as CheckIcon} from '@mui/icons-material';
import {green} from '@mui/material/colors';
import {alpha} from "@mui/material";

import {getCollection} from '../../data/utils';

import firebaseApp from '../../firebase.js';
import {UserContext} from '../../contexts/User';

import Detail from './Detail';

const AutocompleteTypes = params => {
    const {id, field, value: rawValue, hasFocus, trainingTypes = []} = params || {};
    const [value, setValue] = useState(rawValue || []);
    const apiRef = useGridApiContext();
    const ref = useRef();

    const handleChange = useCallback((event, newValue) => {
        setValue(newValue);

        apiRef.current.setEditCellValue({id, field, value: newValue});

        event.stopPropagation();
    }, [apiRef, id, field]);

    useLayoutEffect(() => {
        if (hasFocus) {
            ref.current.focus();
        }
    }, [hasFocus]);

    return (
        <Autocomplete
            ref={ref}
            multiple
            value={value}
            onChange={handleChange}
            options={trainingTypes}
            fullWidth
            getOptionLabel={option => option.name}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    const {name} = option;

                    return (
                        <Chip label={name} {...getTagProps({index})} />
                    );
                })
            }
            renderInput={params => (
                <TextField
                    {...params}
                    variant="standard"
                    placeholder="Types"
                />
            )}
        />
    );
};

const AutocompleteUsers = params => {
    const {id, field, value: rawValue, hasFocus, users = [], placeholder = 'Members'} = params || {};
    const [value, setValue] = useState(rawValue || []);
    const apiRef = useGridApiContext();
    const ref = useRef();

    const handleChange = useCallback((event, newValue) => {
        setValue(newValue);

        apiRef.current.setEditCellValue({id, field, value: newValue});

        event.stopPropagation();
    }, [apiRef, id, field]);

    useLayoutEffect(() => {
        if (hasFocus) {
            ref.current.focus();
        }
    }, [hasFocus]);

    return (
        <Autocomplete
            ref={ref}
            multiple
            value={value}
            onChange={handleChange}
            options={users}
            fullWidth
            getOptionLabel={option => option.fullName}
            isOptionEqualToValue={(option, value) => {
                return value && option.uid === value.uid;
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    const {fullName} = option || {};

                    return (
                        <Chip label={fullName} {...getTagProps({index})} />
                    );
                })
            }
            renderInput={params => (
                <TextField
                    {...params}
                    variant="standard"
                    placeholder={placeholder}
                />
            )}
        />
    );
};

const Grid = props => {
    const {training: rawTraining, columns = [], editMode,  ...rest} = props;
    const [loading, setLoading] = useState(true);
    const [training, setTraining] = useState([]);
    const [members, setMembers] = useState([]);
    const [trainingTypes, setTrainingTypes] = useState([]);
    const db = getFirestore(firebaseApp);
    
    const {currentUser} = useContext(UserContext);
    const {isAdmin} = currentUser;

    useEffect(() => {
        const fetch = async () => {
            const trainingTypesRef = collection(db, 'trainingTypes');
            const trainingTypes = await getCollection(trainingTypesRef);
            setTrainingTypes(trainingTypes);

            const usersRef = collection(db, 'users');
            const users = await getCollection(usersRef);
            setMembers(users.filter(doc => {
                const {deactivated, role} = doc;

                return deactivated !== true && role !== 'STATION';
            }));

            const training = rawTraining.map(row => {
                const {
                    members: rawMembers = [],
                    instructors: rawInstructors = [],
                    types: rawTypes = [],
                    ...rest
                }  = row;

                return {
                    ...rest,
                    members: rawMembers.map(member => users.find(({id}) => id === member)),
                    instructors: rawInstructors.map(instructor => users.find(({id}) => id === instructor)),
                    types: rawTypes.map(type => trainingTypes.find(({id}) => id === type))
                };
            });

            setTraining(training);
            setLoading(false);
        };

        fetch();
    }, [db, rawTraining]);
    
    const defaultColumns = [
        {
            field: 'date',
            type: 'date',
            headerName: 'Date',
            sortable: true,
            width: 120,
            valueGetter: params => {
                const {value: rawValue} = params || {};

                let value = rawValue;
                if (rawValue && rawValue.toDate) {
                    value = rawValue.toDate();
                }

                return value;
            },
            valueFormatter: params => {
                const {value} = params || {};
                return value ? moment(value).format('DD/MM/YYYY') : '-';
            }
        },
        {
            field: 'raw.type',
            headerName: 'Details',
            flex: 1,
            renderCell: params => {
                const {row} = params || {};
                const {raw = {}} = row || {};
                const {type, description} = raw || {};

                return (
                    <Box sx={{display: 'flex', py: 1, flexDirection: 'column', flex: 1}}>
                        <Typography variant="subtitle2" color="textSecondary">{type}</Typography>
                        <Typography variant="body2" color="textSecondary" sx={{fontSize: 10}}>{description}</Typography>
                    </Box>
                );
            }
        },
        {
            field: 'types',
            headerName: 'Types',
            editable: !!editMode,
            flex: 1,
            renderCell: params => {
                const {value = []} = params || {};

                const chips = value.map((trainingType, index) => {
                    const {id, name = '-'} = trainingType || {};

                    return (
                        <Chip key={`${id}-${index}`} sx={{mr: 1, mb: 1}} label={name} />
                    );
                });

                return (
                    <Box sx={{display: 'flex', pt: 1, flexWrap: 'wrap'}}>
                        {chips}
                    </Box>
                );
            },
            renderEditCell: params => (
                <AutocompleteTypes {...params} trainingTypes={trainingTypes} />
            )
        },
        {
            field: 'instructors',
            headerName: 'Instructors',
            editable: !!editMode,
            flex: 1,
            renderCell: params => {
                const {value = []} = params || {};

                const chips = value.map((member, index) => {
                    const {id, fullName = '-'} = member || {};

                    return (
                        <Chip key={`${id}-${index}`} sx={{mr: 1, mb: 1}} label={fullName} />
                    );
                });

                return (
                    <Box sx={{display: 'flex', pt: 1, flexWrap: 'wrap'}}>
                        {chips}
                    </Box>
                );
            },
            renderEditCell: params => (
                <AutocompleteUsers {...params} placeholder="Instructors" users={members} />
            )
        },
        {
            field: 'isCourse',
            headerName: 'Course',
            editable: !!editMode,
            width: 80,
            renderCell: params => {
                const {value} = params || {};

                return value ? <CheckIcon /> : '';
            }
        },
        {
            field: 'hours',
            headerName: 'Hours'
        },
        {
            field: 'members',
            headerName: 'Attendance',
            valueFormatter: params => {
                const {value = []} = params || {};
                return value.length;
            }
        }
    ];

    const getDetailPanelContent = useCallback(({row}) => <Detail row={row} />, []);

    return (
        <DataGridPro
            loading={loading}
            initialState={{
                sorting: {
                    sortModel: [
                        {field: 'date', sort: 'desc'}
                    ]
                },
                columns: {
                    columnVisibilityModel: {
                        incidentTypeCode: false,
                        dispatchType: false
                    }
                }
            }}
            autoHeight
            getRowHeight={() => 'auto'}
            rows={training}
            columns={[
                ...defaultColumns,
                ...columns
            ]}
            pageSizeOptions={[]}
            disableRowSelectionOnClick
            disableColumnFilter
            getDetailPanelHeight={({row}) => 'auto'}
            getDetailPanelContent={isAdmin && getDetailPanelContent}
            getRowClassName={params => {
                const {row} = params;
                const {syncedToVS = false} = row || {};
                const classNames = [];

                if (syncedToVS) {
                    classNames.push('vs-synced');
                }

                return classNames.join(' ');
            }}
            sx={{
                '& .vs-synced': {
                    backgroundColor: `${alpha(green[500], 0.2)}`,
                    borderLeft: `4px solid ${green[500]}`
                },
                '& .MuiDataGrid-row:hover.vs-synced': {
                    backgroundColor: `${alpha(green[500], 0.3)}`,
                    borderLeft: `4px solid ${green[500]}`
                }
            }}
            {...rest}
        />
    );
};

export default Grid;