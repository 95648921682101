import React, {useState, createRef, useEffect, useCallback, useContext} from 'react';
import {DataGridPro, GridToolbarContainer, GridActionsCellItem} from '@mui/x-data-grid-pro';
import {useMediaQuery, Paper, Card, CardActionArea, CardMedia, Chip, Box, Typography, Button, Divider} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useForm, FormProvider, useFormContext, useFieldArray} from 'react-hook-form';
import {getFirestore, collection, getDocs, query, where, orderBy, doc, updateDoc, addDoc, deleteDoc} from 'firebase/firestore';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import {omitBy, isUndefined} from 'lodash';
import {getStorage, ref, uploadBytes, getDownloadURL} from 'firebase/storage';
import {useTheme} from '@mui/material/styles';
import mime from 'mime';

import firebaseApp from '../firebase';
import {UserContext} from '../contexts/User';

import TextField from '../form/TextField.js';
import LocationAutocompleteField from '../form/LocationAutocompleteField.js';

const ItemsToolbar = props => {
    const {handleAdd, saving} = props;
  
    return (
        <GridToolbarContainer>
            <Button color="primary" disabled={saving} startIcon={<AddIcon />} onClick={handleAdd}>
                Add Item
            </Button>
        </GridToolbarContainer>
    );
  }

const Items = props => {
    const {editing, ...rest} = props;
    const {enqueueSnackbar} = useSnackbar();
    const {control, setValue} = useFormContext();

    const {fields, append, remove, swap} = useFieldArray({
        control,
        name: 'items'
    });

    const handleRowUpdate = useCallback(async(data, before) => {
        const {index, ...rest} = data;

        setValue(`items[${index}]`, omitBy(rest, isUndefined));

        return data;
    }, [setValue]);

    // const renderImageCell = useCallback(params => {
    //     const {row} = params;

    //     return (
    //         <UserAvatar user={row} />
    //     );
    // }, []);

    const handleAdd = useCallback(() => {
        append({
            position: fields.length
        });
    }, [append, fields]);

    const handleDelete = useCallback(index => {
        remove(index);
    }, [remove]);

    const handleRowOrderChange = useCallback(async(change) => {
        const {oldIndex, targetIndex} = change;
        swap(oldIndex, targetIndex);
    }, [swap]);

    const columns = [
        // {
        //     field: 'requiredCount',
        //     headerName: 'Required',
        //     type: 'number',
        //     width: 80,
        //     editable: editing,
        //     valueFormatter: ({value}) => value ? `${value}` : '-'
        // },
        {
            field: 'inventoryCount',
            headerName: 'Qty',
            type: 'number',
            width: 60,
            editable: editing,
            valueFormatter: ({value}) => value ? `${value}` : '-'
        },
        {field: 'name', headerName: 'Name', flex: 1, minWidth: 220, editable: editing},
        {field: 'note', headerName: 'Notes', flex: 2, minWidth: 200, editable: editing},
        {
            field: 'inServiceDate', 
            headerName: 'Year In Service',
            width: 140,
            editable: editing,
            type: 'number',
            valueFormatter: ({value}) => value ? `${value}` : '-'
        },
        {
            field: 'expiryDate',
            headerName: 'Expiry Year',
            width: 140,
            editable: editing,
            type: 'number',
            valueFormatter: ({value}) => value ? `${value}` : '-'
        }
    ];

    if (editing) {
        columns.push({
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({row}) => {
                const {index} = row;
                
                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={() => handleDelete(index)}
                        color="inherit"
                    />
                ];
            }
        });
    }

    return (
        <Box {...rest}>
            <DataGridPro
                initialState={{
                    sorting: {
                        sortModel: [
                            {field: 'position', sort: 'asc'}
                        ]
                    }
                }}
                experimentalFeatures={{newEditingApi: true}}
                editMode="row"
                autoHeight
                rowHeight={35}
                rows={fields.map((field, index) => ({...field, index}))}
                columns={columns}
                pageSizeOptions={[]}
                disableRowSelectionOnClick
                disableColumnFilter
                hideFooter
                rowReordering={editing}
                onRowOrderChange={handleRowOrderChange}
                processRowUpdate={handleRowUpdate}
                onProcessRowUpdateError={e => enqueueSnackbar(e.message, {variant: 'error'})}
                components={{
                    Toolbar: editing && ItemsToolbar
                }}
                componentsProps={{
                    toolbar: {
                        handleAdd,
                        editing
                    }
                }}
                sx={{width: '100%'}}
            />
        </Box>
    );
};

const GearImageCard = props => {
    const {id, imageUrl, editing} = props;
    const imageUploadRef = createRef();
    const [imageFile, setImageFile] = useState();
    const storage = getStorage(firebaseApp);
    const db = getFirestore(firebaseApp);
    const theme = useTheme();
    const isAtLeastMedium = useMediaQuery(theme.breakpoints.down('md'));

    const handleImageUpload = () => {
        if (!editing) {
            if (imageUrl) {
                window.open(imageUrl, '_blank');
                return;
            }

            return;
        }

        imageUploadRef.current.click();
    };

    const handleImageChange = useCallback(async file => {
        setImageFile(file);

        if (file) {
            const extension = mime.getExtension(file.type); 
            const reference = ref(storage, `/gear/${id}.${extension}`);
            await uploadBytes(reference, file);
            
            const imageUrl = await getDownloadURL(reference);

            const docRef = doc(db, 'gear', id);
            await updateDoc(docRef, {
                imageUrl
            });
        }
    }, [db, id, storage]);

    return (
        <Card sx={{flex: 1, mr: 2, width: '100%', mb: isAtLeastMedium && 2}}>
            <CardActionArea
                sx={{display: 'flex', alignItems: 'center', minHeight: 200, flex: 1, width: '100%'}}
                onClick={handleImageUpload}
                disabled={!editing && !imageUrl}
            >
                {(imageFile || imageUrl) ? (
                    <CardMedia
                        component="img"
                        image={imageFile ? URL.createObjectURL(imageFile) : imageUrl}
                    />
                ) : (
                    <Typography variant="button" sx={{wdith: '100%'}}>ADD IMAGE</Typography>
                )}
            </CardActionArea>
            <input
                type="file"
                hidden
                ref={imageUploadRef}
                onChange={e => {
                    const file = e.target.files[0];
                    handleImageChange(file);
                }}
            />
        </Card>
    );
};

const Gear = props => {
    const {gear} = props;
    const {id, imageUrl, isNew} = gear;
    const [saving, setSaving] = useState(false);
    const [editing, setEditing] = useState(isNew);
    const {currentUser} = useContext(UserContext);
    const {isOfficer} = currentUser;
    const theme = useTheme();
    const isAtLeastMedium = useMediaQuery(theme.breakpoints.down('md'));

    const db = getFirestore(firebaseApp);
    const methods = useForm({
        defaultValues: {
            name: '',
            location: '',
            imageUrl: ''
        },
        mode: 'onChange'
    });
    const {handleSubmit, reset, watch} = methods;
    const name = watch('name');
    const location = watch('location');
    
    useEffect(() => {
        reset(gear);
    }, [gear, reset]);

    const handleSave = useCallback(async data => {
        setSaving(true);

        const {id, isNew, ...rest} = data;

        try {
            if (id) {
                const ref = doc(db, 'gear', id);
                await updateDoc(ref, {
                    ...rest
                });
            } else {
                const ref = collection(db, 'gear');
                await addDoc(ref, {
                    type: 'ropes',
                    ...rest
                });
            }

            setEditing(false);
            reset(data);
        } catch(e) {
            
        }

        setSaving(false);
    }, [db, reset]);

    const handleDelete = useCallback(async () => {
        const ref = doc(db, 'gear', id);
        await deleteDoc(ref);
    }, [id, db]);

    return (
        <Paper key={`gear-${id}`} sx={{flex: 1, mb: 2, p: 2}}>
            <FormProvider {...methods}>
                <Box sx={{flex: 1, pb: 2}}>
                    {editing ? (
                        <>
                            <TextField
                                sx={{mt: 2}}
                                fullWidth
                                label="Name"
                                name="name"
                            />
                            <LocationAutocompleteField
                                sx={{mt: 2}}
                                margin="normal"
                                fullWidth
                                label="Location"
                                name="location"
                            />
                        </>
                    ) : (
                        <>
                            <Typography variant="h4">{name}</Typography>
                            <Divider sx={{mt: 1, mb: 1}} />
                            {location && <Chip label={location && location.label} />}
                        </>
                    )}
                </Box>

                <Box sx={{display: 'flex', flexDirection: isAtLeastMedium ? 'column' : 'row', alignItems: 'flex-start'}}>
                    {(imageUrl || (isOfficer && editing)) && <GearImageCard id={id} imageUrl={imageUrl} editing={editing} />}

                    <Items editing={editing} sx={{width: '100%', flex: 2}} />
                </Box>

                <Box sx={{display: 'flex', justifyContent: 'flex-end', pt: 2}}>
                    {editing ? (
                        <>
                            <Button disabled={saving} onClick={() => setEditing(false)} sx={{mr: 1}}>
                                Cancel
                            </Button>
                            <Button variant="outlined" disabled={saving} startIcon={<SaveIcon />} onClick={handleSubmit(handleSave)}>
                                Save Changes
                            </Button>
                        </>
                    ) : isOfficer && (
                        <>
                            <Button variant="outlined" onClick={handleDelete} sx={{mr: 1}}>
                                Delete
                            </Button>
                            <Button variant="outlined" onClick={() => setEditing(true)}>
                                Edit
                            </Button>
                        </>
                    )}
                </Box>
            </FormProvider>
        </Paper>
    );
};

const Container = () => {
    const [loading, setLoading] = useState(true);
    const [docs, setDocs] = useState([]);
    const db = getFirestore(firebaseApp);
    const {enqueueSnackbar} = useSnackbar();
    const {currentUser} = useContext(UserContext);
    const {isOfficer} = currentUser;

    useEffect(() => {
        let isSubscribed = true;

        async function fetch() {
            try {
                const ref = collection(db, 'gear');
                const q = query(ref, where('type', '==', 'ropes'), orderBy('name', 'asc'));
                const raw = await getDocs(q);
                let docs = [];

                raw.forEach(doc => {
                    const data = doc.data();

                    docs.push({
                        id: doc.id,
                        uid: doc.id,
                        ...data
                    });
                });

                if (isSubscribed) {
                    setDocs(docs);
                }
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }

            if (isSubscribed) {
                setLoading(false);
            }
        }

        fetch();
        
        return () => isSubscribed = false;
    }, [enqueueSnackbar, db]);

    const handleAdd = useCallback(() => {
        setDocs([...docs, {
            isNew: true,
            location: null,
            items: []
        }]);
    }, [setDocs, docs]);

    return (
        <Box style={{display: 'flex', flexDirection: 'column'}}>
            {docs.map(doc => {
                const {id} = doc;

                return <Gear gear={doc} key={`gear-${id}`} />;
            })}

            {isOfficer && (
                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button onClick={handleAdd} variant="outlined" sx={{mt: 2}}>Add Gear Location</Button>
                </Box>
            )}
        </Box>
    );
};

export default Container;