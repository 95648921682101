import {createTheme} from '@mui/material/styles';

export default createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#ce1717'
        },
        secondary: {
            main: '#263a83'
        },
        info: {
            main: '#fff9c4'
        },

        // Stages
        complete: {
            main: '#7cb342',
            contrastText: '#fff'
        },
        needed: {
            main: '#ffca28',
            contrastText: '#fff'
        },
        inProgress: {
            main: '#ff9800',
            contrastText: '#fff'
        }
    }
});