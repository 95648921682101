import React from 'react';
import {Avatar} from '@mui/material';
import {useTheme} from '@mui/material/styles';

const stringToColor = string => {
    if (!string) {
        return;
    }

    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
};

// theme.palette

export default function UserAvatar(props) {
    const {user, sx, ...rest} = props;
    const theme = useTheme();
    const {imageUrl, role, firstName = '', lastName = '', fullName = ''} = user || {};
    
    let displayText = `${firstName[0]}${lastName[0]}`;
    let bgcolor = stringToColor(fullName);
    if (role === 'STATION') {
        displayText = lastName;
        bgcolor = theme.palette.primary.main;
    }

    return (
        <Avatar
            src={imageUrl}
            alt={fullName}
            sx={{
                color: '#fff !important',
                bgcolor,
                ...sx
            }}
            {...rest}
        >
            {displayText}
        </Avatar>
    );
};