import React from 'react';
import {Controller} from 'react-hook-form';
import {FormControl, ToggleButton, ToggleButtonGroup} from '@mui/material';

export default function ControlledToggleButtonField(props) {
    const {name, rules, label, options, fullWidth, margin, sx, button, ...rest} = props;

    return (
        <Controller
            name={name}
            rules={rules}
            render={({field, fieldState: {error}}) => {
                const {value, onChange, ...restField} = field;

                return (
                    <FormControl fullWidth={fullWidth} margin={margin} sx={sx}>
                        <ToggleButtonGroup
                            value={value}
                            variant="outlined"
                            onChange={(e, values) => {
                                if (values && values.length) {
                                    onChange(values);
                                } else {
                                    onChange([]);
                                }
                            }}
                            {...rest}
                            {...restField}
                        >
                            {options.map(option => (
                                <ToggleButton {...button} key={option.value} value={option.value} {...option.color && {color: option.color}}>{option.label}</ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </FormControl>
                );
            }}
        />
    );
};
