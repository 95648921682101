import React, {useState, useEffect, useContext, useCallback} from 'react';
import {useTheme} from '@mui/material/styles';
import {Box, Typography, Button, Divider, AvatarGroup, useMediaQuery} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import {useParams} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import moment from 'moment';
import {get} from 'lodash';
import {LoadingButton} from '@mui/lab';
import {useNavigate} from 'react-router-dom';
import {getFirestore, collection, query, getDocs, addDoc, limit, where, orderBy, onSnapshot} from 'firebase/firestore';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import {UserContext} from '../contexts/User';
import firebaseApp from '../firebase';

import {populateUsers} from '../data/utils';

import UserAvatar from '../components/UserAvatar';

const Checks = () => {
    const [loading, setLoading] = useState(true);
    const [checks, setChecks] = useState([]);
    const [activeChecks, setActiveChecks] = useState([]);
    const db = getFirestore(firebaseApp);
    const {enqueueSnackbar} = useSnackbar();
    const {currentUser, member} = useContext(UserContext);
    const {id: uid, type} = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const {isOfficer = false} = currentUser;

    let isSubscribed = true;

    const handleFetch = async(raw) => {
        try {
            let docs = [];

            raw.forEach(doc => {
                const data = doc.data();

                docs.push({
                    id: doc.id,
                    uid: doc.id,
                    ...data
                });
            });

            docs = await populateUsers(db, docs, true);

            if (isSubscribed) {
                const activeChecks = docs.filter(doc => doc.active);
                const inactiveChecks = docs.filter(doc => !doc.active);

                setActiveChecks(activeChecks);
                setChecks(inactiveChecks);
            }
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        if (isSubscribed) {
            setLoading(false);
        }
    };

    const handleStartCheck = useCallback(async () => {
        const ref = collection(db, 'checks');
        const q = query(ref, where('apparatus', '==', uid), where('type', '==', type), orderBy('createdAt', 'desc'), limit(1));
        const raw = await getDocs(q);
        let docs = [];

        raw.forEach(doc => {
            const data = doc.data();

            docs.push({
                id: doc.id,
                uid: doc.id,
                ...data
            });
        });

        const [record] = docs || [];
        if (!record) {
            return;
        }

        const {checks = []} = record;
        
        const data = {
            type,
            loggedChecks: checks,
            createdAt: new Date(),
            active: true,
            users: []
        };

        const checkRef = collection(db, 'apparatus', uid, 'checks');
        const checkRecordRaw = await addDoc(checkRef, data);

        navigate(`/${uid}/${type}/${checkRecordRaw.id}`);
    }, [navigate, uid, type, db]);

    useEffect(() => {
        const ref = collection(db, 'apparatus', uid, 'checks');
        const q = query(ref, where('type', '==', type), orderBy('createdAt', 'desc'), limit(10));
        const snapshot = onSnapshot(q, handleFetch);
        
        return () => {
            snapshot();
            isSubscribed = false;
        };
    }, [enqueueSnackbar, db, uid, type]);

    const columns = [
        {
            field: 'createdAt',
            headerName: 'Completed',
            ...(isSmall ? {width: 110} : {flex : 1}),
            sortable: true,
            valueFormatter: params => {
                let value = get(params, 'value');
                if (!value) {
                    return '-';
                }

                value = value.toDate ? value.toDate() : value;
                
                return moment(value).format(isSmall ? 'MMM D, HH:mm' : 'dddd, MMMM Do, HH:mm');
            }
        },
        {
            field: 'users',
            headerName: 'Members',
            renderCell: params => {
                const {row} = params;
                const {users = []} = row || {};

                if (!users || !users.length) {
                    return '-';
                }

                return (
                    <AvatarGroup>
                        {users.map(user => {
                            const {uid} = user;

                            return (
                                <UserAvatar key={`avatar-${uid}`} user={user} />
                            );
                        })}
                    </AvatarGroup> 
                );
            },
            flex: 1
        },
        {
            field: 'notes',
            headerName: 'Notes',
            width: isSmall ? 60 : 90,
            sortable: false,
            renderCell: params => {
                const {row} = params;
                const {loggedChecks = []} = row;
                const hasNote = loggedChecks.find(check => !!check.note);

                if (!hasNote) {
                    return;
                }

                return (
                    <Box sx={{display: 'flex', flex: 1, justifyContent: 'center'}}>
                        <WarningAmberIcon />
                    </Box>
                );
            }
        }
    ];

    const activeColumns = [
        // {
        //     field: 'updatedAt',
        //     headerName: 'Last Update',
        //     ...isSmall ? {width: 135} : {flex : 1},
        //     sortable: true,
        //     valueFormatter: params => {
        //         let value = get(params, 'value');
        //         if (!value) {
        //             return '-';
        //         }

        //         value = value.toDate ? value.toDate() : value;
                
        //         return moment(value).fromNow();
        //     }
        // },
        {
            field: 'users',
            headerName: 'Members',
            renderCell: params => {
                const {row} = params;
                const {users = []} = row || {};

                if (!users || !users.length) {
                    return '-';
                }

                return (
                    <AvatarGroup>
                        {users.map(user => {
                            const {uid} = user;

                            return (
                                <UserAvatar key={`avatar-${uid}`} user={user} />
                            );
                        })}
                    </AvatarGroup> 
                );
            },
            flex: 1
        },
        {
            field: 'percent',
            headerName: '%',
            width: isSmall ? 60 : 90,
            sortable: false,
            renderCell: params => {
                const {row} = params;
                const {loggedChecks = []} = row;
                const completedChecks = loggedChecks.filter(check => check.completed || check.skipped);

                return `${Math.round((completedChecks.length / loggedChecks.length) * 100)}%`;
            }
        }
    ];

    return (
        <Box>
            {activeChecks.length > 0 && (
                <>
                    <Typography variant="h6" gutterBottom>Active Checks</Typography>

                    <Box sx={{display: 'flex'}}>
                        <DataGrid
                            hideFooter
                            loading={loading}
                            autoHeight
                            rowHeight={60}
                            rows={activeChecks}
                            columns={activeColumns}
                            disableRowSelectionOnClick
                            disableColumnFilter
                            disableColumnSelector
                            disableColumnMenu
                            onRowClick={params => {
                                const {row} = params;

                                navigate(`/${uid}/${type}/${row.uid}`);
                            }}
                        />
                    </Box>

                    <Divider sx={{mt: 2, mb: 2}} />
                </>   
            )}

            <Typography variant="h6" gutterBottom>History</Typography>

            <Box sx={{display: 'flex'}}>
                <DataGrid
                    localeText={{
                        noRowsLabel: `No ${type} checks yet`
                    }}
                    hideFooter
                    loading={loading}
                    autoHeight
                    rowHeight={60}
                    rows={checks}
                    columns={columns}
                    disableRowSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableColumnMenu
                    onRowClick={params => {
                        const {row} = params;

                        navigate(`/${uid}/${type}/${row.uid}`);
                    }}
                />
            </Box>

            <Box sx={{mt: 2}}>
                <LoadingButton
                    variant="contained"
                    size="large"
                    onClick={handleStartCheck}
                    disabled={activeChecks.length > 0}
                    fullWidth
                >
                    Start Truck Check
                </LoadingButton>
                
                {isOfficer && (
                    <Button
                        sx={{mt: 1}}
                        size="large"
                        onClick={() => navigate(`/${uid}/${type}/edit`)}
                        fullWidth
                    >
                        Edit Checklist
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default Checks;