import React, {useState, useEffect, useCallback} from "react";
import {Box, Grid, Button, useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {useNavigate} from 'react-router-dom';
import {useForm, useFormContext, FormProvider} from 'react-hook-form';
import {getFirestore, doc, getDoc, deleteDoc, updateDoc} from 'firebase/firestore';
import {useParams} from 'react-router-dom';
import {LoadingButton} from '@mui/lab';
import {useSnackbar} from 'notistack';

import firebaseApp from '../firebase';

import {IncidentTypes} from '../data/utils';

import TextField from '../form/TextField.js';
import DatePickerField from '../form/DatePickerField.js';
import SelectField from '../form/SelectField.js';
import UserAutocomplete from '../form/UserAutocomplete';

import Map from './incidents/Map';

const Location = props => {
    const {loading} = props;
    const {watch} = useFormContext();
    const type = watch('type');
    const location = watch('location');

    return (
        <>
            <Box sx={{display: 'flex', flexDirection: 'row', mb: 4}}>
                <Map sx={{flex: 1}} incident={{type, location}} editable={!loading} mapContainerStyle={{height: 300}} />
            </Box>

            <Box sx={{display: 'flex', flexDirection: 'row', mb: 2}}>
                <TextField
                    fullWidth
                    label="Location"
                    name="street"
                    disabled={loading}
                    helperText="Drag the marker on the map to change lat/long"
                />
            </Box>

            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                <TextField
                    sx={{mr: 1, flex: 1}}
                    label="Latitude"
                    name="location.latitude"
                    InputProps={{
                        readOnly: true
                    }}
                    disabled={true}
                />
                <TextField
                    sx={{flex: 1}}
                    label="Longitude"
                    name="location.longitude"
                    InputProps={{
                        readOnly: true
                    }}
                    disabled={true}
                />
            </Box>
        </>
    );
};

export default function User(props) {
    const {activeTab = 0} = props;

    const {id: uid} = useParams();
    const [loading, setLoading] = useState(!!uid);
    const [deleting, setDeleting] = useState(false);
    const db = getFirestore(firebaseApp);
    const navigate = useNavigate();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const methods = useForm({
        defaultValues: {
            type: ''
        },
        mode: 'onChange'
    });
    const {handleSubmit, formState, reset} = methods;
    const {isValid, dirtyFields} = formState;
    const dirtyKeys = Object.keys(dirtyFields);

    const onSubmit = useCallback(async data => {
        const dirtyKeys = Object.keys(dirtyFields);
        if (!dirtyKeys.length) {
            return;
        }

        setLoading(true);

        const newData = dirtyKeys.reduce((acc, key) => {
            acc[key] = data[key];
            return acc;
        }, {});

        try {
            const ref = doc(db, 'incidents', uid);
            await updateDoc(ref, {
                updated: new Date(),
                ...newData
            });

            reset(data);

            enqueueSnackbar('Changed saved', {variant: 'success'});
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setLoading(false);
    }, [dirtyFields, db, uid, enqueueSnackbar, reset]);

    const handleDelete = useCallback(async() => {
        const onDelete = async() => {
            try {
                setDeleting(true);
    
                const ref = doc(db, 'incidents', uid);
                await deleteDoc(ref);

                navigate('/incidents');
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }
    
            setDeleting(false);
        };

        enqueueSnackbar('Are you sure you want to delete this incident?', {
            variant: 'warning',
            action: key => {
                return (
                    <>
                        <Button onClick={() => {
                            closeSnackbar(key);
                            onDelete();
                        }}>
                            Delete
                        </Button>
                        <Button onClick={() => closeSnackbar(key)}>
                            Cancel
                        </Button>
                    </>
                );
            }
        });
    }, [closeSnackbar, enqueueSnackbar]);

    useEffect(() => {
        let isSubscribed = true;

        const fetch = async() => {
            if (uid) {
                const ref = doc(db, 'incidents', uid);
                const raw = await getDoc(ref);
                
                if (isSubscribed) {
                    if (!raw.exists()) {
                        navigate(-1);
                    }

                    const {date: rawDate, ...rest} = raw.data();
                    const date = rawDate.toDate();
                    
                    reset({
                        id: uid,
                        uid,
                        date,
                        ...rest
                    });

                    setLoading(false);
                }
            }
        };

        fetch();

        return () => isSubscribed = false;
    }, [db, reset, uid, navigate]);

    const typeOptions = Object.keys(IncidentTypes).map(key => {
        const label = IncidentTypes[key];
        return {value: key, label};
    });

    return (
        <FormProvider {...methods}>
            {activeTab === 0 && (
                <Box component="form" sx={{mt: 1, display: 'flex', flexDirection: isSmall ? 'column' : 'row'}}>
                    <Grid container spacing={2}>
                        <Grid item md={4} xs={12}>
                            <Location {...{loading}} />
                        </Grid>

                        <Grid item md={8}>
                            <Box sx={{display: 'flex', flexDirection: 'row', mb: 2}}>
                                <TextField
                                    fullWidth
                                    label="Incident #"
                                    name="incidentNumber"
                                    disabled={loading}
                                />
                            </Box>

                            <Box sx={{display: 'flex', flexDirection: 'row', mb: 2}}>
                                <DatePickerField
                                    sx={{mr: 1}}
                                    fullWidth
                                    label="Date"
                                    name="date"
                                    disabled={loading}
                                />
                                <TextField
                                    sx={{mr: 1}}
                                    fullWidth
                                    label="Dispatch Time"
                                    name="dispatchTime"
                                    disabled={loading}
                                />
                                <TextField
                                    sx={{mr: 1}}
                                    fullWidth
                                    label="Arrival Time"
                                    name="arrivalTime"
                                    disabled={loading}
                                />
                                <TextField
                                    fullWidth
                                    label="Scene Cleared"
                                    name="sceneCleared"
                                    disabled={loading}
                                />
                            </Box>

                            <Box sx={{display: 'flex', flexDirection: 'row', mb: 2}}>
                                <SelectField
                                    sx={{mr: 1, flex: 2}}
                                    label="Type"
                                    name="type"
                                    disabled={loading}
                                    options={typeOptions}
                                />

                                <TextField
                                    sx={{flex: 1}}
                                    label="Incident Type Code"
                                    name="incidentTypeCode"
                                    disabled={loading}
                                />
                            </Box>

                            <Box sx={{display: 'flex', flexDirection: 'row', mb: 2}}>
                                <TextField
                                    fullWidth
                                    label="Notes"
                                    name="notes"
                                    multiline
                                    rows={4}
                                    disabled={loading}
                                />
                            </Box>

                            <Box sx={{display: 'flex', flexDirection: 'row', mb: 2}}>
                                <UserAutocomplete
                                    fullWidth
                                    label="Members"
                                    name="members"
                                    disabled={loading}
                                />
                            </Box>

                            <Box sx={{display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', mb: 2}}>
                                <LoadingButton onClick={handleDelete} sx={{mr: 1}} loading={deleting} variant="outlined" component="label">
                                    Delete Incident
                                </LoadingButton>
                                
                                <LoadingButton
                                    type="submit"
                                    size="large"
                                    variant="contained"
                                    onClick={handleSubmit(onSubmit)}
                                    disabled={loading || !isValid || dirtyKeys.length === 0}
                                    loading={loading}
                                >
                                    Save Changes
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </FormProvider>
    );
}