import * as React from 'react';
import {createRoot} from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import App from './App';
import * as serviceWorker from './serviceWorker';

import './style/style.css';

const container = document.getElementById('root');
const root = createRoot(container);
root.render((
    <>
        <CssBaseline />
        <App />
    </>
));

serviceWorker.unregister();