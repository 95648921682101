import React, {useState, useCallback, useContext, useEffect} from 'react';
import {get} from 'lodash';
import {getFirestore, doc, onSnapshot} from 'firebase/firestore';

import firebaseApp from '../../firebase.js';
import {UserContext} from '../../contexts/User';

import {verifyOfficer, Skills} from '../../data/utils';

import MemberTraining from '../../dialogs/MemberTraining';

import {TrainingGrid, renderTrainingCell} from '../matrix';

export default function User(props) {
    const {user: rawUser} = props;

    const [user, setUser] = useState(rawUser || {});
    const [loading, setLoading] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [skillsGridRows, setSkillsGridRows] = useState([]);
    const db = getFirestore(firebaseApp);
    
    const {currentUser} = useContext(UserContext);
    const isOfficer = verifyOfficer(currentUser);

    const handleFetch = async(raw) => {
        let userData = {
            id: raw.id,
            uid: raw.id,
            ...raw.data()
        };

        setLoading(false);
        setUser(userData);

        let newSetSkillsGridRows = [];

        Object.keys(Skills).forEach(skill => {
            const {label, skills} = Skills[skill];

            newSetSkillsGridRows.push({
                id: skill,
                path: label,
                isLevel: true
            });

            Object.keys(skills).forEach(subSkill => {
                const rawSubSkill = skills[subSkill];
                const {label: subSkillLabel, skill: subSkillSkill} = typeof rawSubSkill === 'string' ? {label: rawSubSkill} : rawSubSkill;
                const field = `skills.${skill}.${subSkill}`;
                let value = get(userData, field, 'INCOMPLETE');
                value = typeof value === 'string' ? {status: value} : value;

                if (subSkillSkill) {
                    return;
                }

                newSetSkillsGridRows.push({
                    id: `${label}/${subSkillLabel}`,
                    path: `${label}/${subSkillLabel}`,
                    field,
                    ...value
                });
            });
        });

        if (isSubscribed) {
            setSkillsGridRows(newSetSkillsGridRows);
        }
    };
    
    let isSubscribed = true;

    useEffect(() => {
        const {uid} = rawUser || {};
        if (!uid) {
            return;
        }

        const ref = doc(db, 'users', uid);
        const snapshot = onSnapshot(ref, handleFetch);
        
        return () => {
            snapshot();
            isSubscribed = false;
        };
    }, [rawUser, currentUser]);

    const handleCellClick = useCallback(params => {
        const {row} = params;
        const {field} = row;

        if (!isOfficer) {
            return;
        }

        setDialogOpen({
            field,
            member: user
        });
    }, [isOfficer, user]);

    return <>
        {isOfficer && !!dialogOpen && (
            <MemberTraining open={!!dialogOpen} {...dialogOpen} onClose={() => setDialogOpen(false)} />
        )}

        <TrainingGrid
            loading={loading}
            columns={[{
                field: 'status',
                flex: 1,
                headerName: 'Status',
                sortable: false,
                valueGetter: params => {
                    const {field, row} = params;
                    const {uid} = row;
                    const value = get(row, field, uid && 'INCOMPLETE');

                    return typeof value === 'string' ? {status: value} : value || {};
                },
                renderCell: renderTrainingCell()
            }]}
            treeData
            getTreeDataPath={row => row.path.split('/')}
            onCellClick={handleCellClick}
            groupingColDef={{ 
                headerName: 'Level',
                flex: 1,
                valueGetter: params => {
                    const {rowNode} = params;
                    const {groupingKey} = rowNode;
    
                    return groupingKey;
                },
                cellClassName: params => {
                    const {rowNode} = params;
                    const {depth = 0} = rowNode;
                    
                    if (depth === 0) {
                        return 'grouping-cell-header';
                    }

                    return 'grouping-cell';
                }
            }}
            rows={skillsGridRows}
            defaultGroupingExpansionDepth={-1}
        />
    </>;
}