import React from 'react';
import {Controller} from 'react-hook-form';
import {Select, FormControl, InputLabel, MenuItem} from '@mui/material';

export default function SelectField(props) {
    const {name, rules, label, options, fullWidth, margin, sx, ...rest} = props;

    return (
        <Controller
            name={name}
            rules={rules}
            render={({field}) => {
                return (
                    <FormControl fullWidth={fullWidth} margin={margin} sx={sx}>
                        <InputLabel id={`${name}-label`}>{label}</InputLabel>
                        <Select
                            label={label}
                            {...rest}
                            {...field}
                        >
                            {options.map(option => (
                                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            }}
        />
    );
};
