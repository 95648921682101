import React, {useState, useCallback} from 'react';
import {Box} from '@mui/material';
import {getFirestore, writeBatch, doc} from 'firebase/firestore';
import {useSnackbar} from 'notistack';
import {omit} from 'lodash';
import {useNavigate, useLocation} from 'react-router-dom';
import {LoadingButton} from '@mui/lab';

import firebaseApp from '../../firebase.js';

import TrainingGrid from './Grid';

const ImportButton = ({selectionCount, ...rest}) => {
    return (
        <LoadingButton variant="contained" {...rest}>
            Import Selected ({selectionCount})
        </LoadingButton>
    );
};

export default function Import(props) {
    const {state} = useLocation();

    const [training, setTraining] = useState(state.training || []);
    const [importing, setImporting] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const db = getFirestore(firebaseApp);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const navigate = useNavigate();

    const handleImport = useCallback(async () => {
        const toImport = training.filter(item => {
            const {uniqueId} = item;
            return rowSelectionModel.includes(uniqueId);
        });

        if (!toImport.length) {
            enqueueSnackbar('No training selected', {variant: 'error'});
            return;
        }

        setImporting(true);

        try {
            const batch = writeBatch(db);

            for (const item of training) {
                const {uniqueId} = item;
                const trainingRef = doc(db, 'training', uniqueId);

                const {instructors, types, ...rest} = omit(item, ['foundExisting', 'issues']);

                batch.set(trainingRef, {
                    ...rest,
                    instructors,
                    types
                });
            }

            await batch.commit();
            
            enqueueSnackbar('Training imported', {variant: 'success'});

            navigate('/training');
        } catch(e) {
            enqueueSnackbar(`Error importing incidents: ${e}`, {variant: 'error'});
        }

        setImporting(false);
    }, [enqueueSnackbar, training, rowSelectionModel, db, navigate]);

    const handleProcessRowUpdate = useCallback(async(updatedRow, originalRow) => {
        const {types = [], instructors = []} = updatedRow;

        setTraining(training.map(row => {
            if (row.uniqueId === originalRow.uniqueId) {
                return {
                    ...row,
                    types: types.map(type => type.id),
                    instructors: instructors.map(instructor => instructor.id)
                };
            }

            return row;
        }));

        return updatedRow;
    }, [training]);

    return (
        <Box>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                <ImportButton loading={importing} onClick={handleImport} selectionCount={rowSelectionModel.length} sx={{mb: 2}} />
            </Box>

            <TrainingGrid
                training={training}
                editMode="row"
                getRowId={row => row.uniqueId}
                checkboxSelection
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                }}
                processRowUpdate={handleProcessRowUpdate}
                onProcessRowUpdateError={e => console.warn(e)}
                rowSelectionModel={rowSelectionModel}
                getRowClassName={params => {
                    const {row} = params;
                    const {foundExisting = false, issues = []} = row || {};
                    const classNames = [];

                    if (foundExisting) {
                        classNames.push('duplicate');
                    }

                    if (issues.length) {
                        classNames.push('has-issues');
                    }

                    return classNames.join(' ');
                }}
                sx={{
                    '& .has-issues': {
                        backgroundColor: 'rgba(250, 250, 0, .1)'
                    },
                    '& .duplicate': {
                        borderLeft: '5px solid rgba(255, 0, 0, 0.9)',
                        borderRight: '5px solid rgba(255, 0, 0, 0.9)'
                    }
                }}
                columns={[
                    {
                        field: 'foundExisting',
                        headerName: 'Duplicate',
                        width: 100,
                        renderCell: params => {
                            const {row} = params;
                            const {foundExisting = false} = row || {};
                            return foundExisting ? 'Yes' : 'No';
                        }
                    }
                ]}
            />

            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                <ImportButton loading={importing} onClick={handleImport} selectionCount={rowSelectionModel.length} sx={{mt: 2}} />
            </Box>
        </Box>
    );
}
