import React, {useState, useEffect, useCallback, useLayoutEffect, useRef} from 'react';
import {GridActionsCellItem, GridToolbarContainer, GridRowModes, useGridApiContext, useGridApiRef} from '@mui/x-data-grid';
import {Button, Chip, Autocomplete, TextField} from '@mui/material';
import {useSnackbar} from 'notistack';
import {getFirestore, collection, doc, addDoc, deleteDoc, updateDoc, getDocs, query, orderBy} from 'firebase/firestore';
import moment from 'moment';
import {
    Add as AddIcon,
    Cancel as CancelIcon,
    Save as SaveIcon,
    Edit as EditIcon,
    Delete as DeleteIcon
} from '@mui/icons-material';

import firebaseApp from '../../firebase.js';

import StatefulDataGrid from '../../components/StatefulDataGrid';

const AutocompleteTags = params => {
    const {id, field, value: rawValue, hasFocus} = params || {};
    const [value, setValue] = useState(rawValue || []);
    const apiRef = useGridApiContext();
    const ref = useRef();

    const handleChange = useCallback((event, newValue) => {
        setValue(newValue);

        apiRef.current.setEditCellValue({id, field, value: newValue});

        event.stopPropagation();
    }, [apiRef, id, field]);

    useLayoutEffect(() => {
        if (hasFocus) {
            ref.current.focus();
        }
    }, [hasFocus]);

    return (
        <Autocomplete
            ref={ref}
            multiple
            freeSolo
            value={value}
            onChange={handleChange}
            options={[]}
            fullWidth
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip label={option} {...getTagProps({index})} />
                ))
            }
            renderInput={params => (
                <TextField
                    {...params}
                    placeholder="Tags"
                />
            )}
        />
    );
};

const EditToolbar = props => {
    const {docs, setDocs, setRowModesModel} = props;

    const handleClick = () => {
        const id = 'new';

        if (!docs.some(row => row.id === id)) {
            setDocs(oldRows => [...oldRows, {id, name: '', tags: []}]);
        }

        setRowModesModel(oldModel => ({
            ...oldModel,
            [id]: {mode: GridRowModes.Edit, fieldToFocus: 'name'},
        }));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Add Training Type
            </Button>
        </GridToolbarContainer>
    );
}

const Types = () => {
    const [loading, setLoading] = useState(true);
    const [docs, setDocs] = useState([]);
    const db = getFirestore(firebaseApp);
    const {enqueueSnackbar} = useSnackbar();
    const apiRef = useGridApiRef();
    const [rowModesModel, setRowModesModel] = useState({});

    useEffect(() => {
        let isSubscribed = true;

        async function fetch() {
            try {
                const ref = collection(db, 'trainingTypes');
                const q = query(ref, orderBy('updatedAt', 'desc'));
                const raw = await getDocs(q);
                let docs = [];

                raw.forEach(doc => {
                    const {updatedAt: updatedAtRaw, ...data} = doc.data();
                    const updatedAt = updatedAtRaw.toDate();

                    docs.push({
                        id: doc.id,
                        uid: doc.id,
                        updatedAt,
                        ...data
                    });
                });

                if (isSubscribed) {
                    setDocs(docs);
                }
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }

            if (isSubscribed) {
                setLoading(false);
            }
        }

        fetch();
        
        return () => isSubscribed = false;
    }, [enqueueSnackbar, db]);

    const handleRowModesModelChange = newRowModesModel => {
        setRowModesModel(newRowModesModel);
    };

    const handleProcessRowUpdate = useCallback(async(updatedRow, originalRow) => {
        const {id, name, tags} = updatedRow;
        const {name: originalName, tags: originalTags} = originalRow;

        if (name === originalName && tags === originalTags) {
            return updatedRow;
        }

        try {
            if (id === 'new') {
                const createdAt = new Date();
                const ref = collection(db, 'trainingTypes');
                const {id} = await addDoc(ref, {
                    name,
                    tags,
                    createdAt,
                    updatedAt: createdAt
                });

                enqueueSnackbar('Training type added', {variant: 'success'});

                setDocs([
                    ...docs.filter(row => row.id !== 'new'),
                    {
                        id,
                        name,
                        tags,
                        createdAt,
                        updatedAt: createdAt
                    }
                ]);

                return {
                    ...updatedRow,
                    id
                };
            } else {
                const updatedAt = new Date();

                const ref = doc(db, 'trainingTypes', id);
                await updateDoc(ref, {
                    name,
                    tags,
                    updatedAt
                });

                enqueueSnackbar('Training type updated', {variant: 'success'});

                return {
                    ...updatedRow,
                    updatedAt
                };
            }
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }
    }, [db, enqueueSnackbar, docs]);

    const handleProcessRowUpdateError = useCallback(error => {

    }, []);

    const handleEditClick = id => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
    };

    const handleSaveClick = id => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
    };

    const handleDeleteClick = id => async() => {
        const ref = doc(db, 'trainingTypes', id);
        await deleteDoc(ref);
            
        setDocs(docs.filter(row => row.id !== id));
    };

    const handleCancelClick = id => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true}
        });

        const editedRow = docs.find(row => row.id === id);
        if (editedRow.id === 'new') {
            setDocs(docs.filter(row => row.id !== id));
        }
    };

    const columns = [
        {field: 'name', headerName: 'Name', flex: 1, editable: true},
        {
            field: 'tags',
            headerName: 'Tags for Import',
            editable: true,
            flex: 2,
            renderCell: params => {
                const {value, row} = params || {};
                if (!value) {
                    return '';
                }

                const {id} = row || {};

                return value.map((tag, index) => {
                    return (
                        <Chip key={`${id}-${index}`} sx={{mr: 1}} label={tag} />
                    );
                });
            },
            renderEditCell: params => (
                <AutocompleteTags {...params} />
            )
        },
        {
            field: 'updatedAt',
            headerName: 'Last Updated',
            width: 150,
            valueFormatter: params => {
                const {value} = params || {};
                if (!value) {
                    return '';
                }

                return moment(value).fromNow();
            }
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            }
        }
    ];

    return <>
        <div style={{display: 'flex', height: '100%'}}>
            <div style={{flexGrow: 1}}>
                <StatefulDataGrid
                    apiRef={apiRef}
                    stateId="trainingTypes"
                    initialState={{
                        sorting: {
                            sortModel: [
                                {field: 'updatedAt', sort: 'desc'}
                            ]
                        },
                        columns: {
                            columnVisibilityModel: {
                                
                            }
                        }
                    }}
                    loading={loading}
                    autoHeight
                    rows={docs}
                    columns={columns}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    processRowUpdate={handleProcessRowUpdate}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                    slots={{
                        toolbar: EditToolbar,
                    }}
                    slotProps={{
                        toolbar: {docs, setDocs, setRowModesModel},
                    }}
                />
            </div>
        </div>
    </>;
};

export default Types;