import React, {useState, useEffect} from 'react';
import {TextField, Autocomplete} from '@mui/material';
import {Controller} from 'react-hook-form';
import {getFirestore, getDocs} from 'firebase/firestore';
import {useSnackbar} from 'notistack';

import firebaseApp from '../firebase';

export default function ControlledAutocompleteField(props) {
    const {name, label, rules, query, ...rest} = props;

    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState([]);

    const db = getFirestore(firebaseApp);
    const {enqueueSnackbar} = useSnackbar();
    
    useEffect(() => {
        let isSubscribed = true;

        async function fetch() {
            try {
                const raw = await getDocs(query);
                let docs = [];

                console.log('fetching...');

                raw.forEach(doc => {
                    const data = doc.data();

                    docs.push({
                        id: doc.id,
                        uid: doc.id,
                        ...data
                    });
                });

                if (isSubscribed) {
                    setOptions(docs);
                }
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }

            if (isSubscribed) {
                setLoading(false);
            }
        }

        fetch();
        
        return () => isSubscribed = false;
    }, [enqueueSnackbar, db]);

    return (
        <Controller
            name={name}
            rules={rules}
            render={({field, fieldState: {error}}) => {
                const {value, onChange, ...restField} = field;

                return (
                    <Autocomplete
                        {...rest}
                        {...restField}
                        loading={loading}
                        multiple
                        value={value ? value.map(item => {
                            return options.find(option => option.id === item);
                        }) : []}
                        options={options}
                        onChange={(e, newValue) => {
                            onChange(newValue.map(item => item.id));
                        }}
                        filterSelectedOptions
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={label}
                            />
                        )}
                    />
                );
            }}
        />
    );
};
