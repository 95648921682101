import React, {useContext, useCallback, useState} from 'react';
import {Box, TableContainer, Table, TableHead, Button, TableRow, TableCell, Paper, TableBody} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {LoadingButton} from '@mui/lab';
import {isNil, sortBy} from 'lodash';
import {Code as CodeIcon} from '@mui/icons-material';
import ReactJson from '@microlink/react-json-view';
import {useSnackbar} from 'notistack';
import {getFirestore, doc, updateDoc} from 'firebase/firestore';

import firebaseApp from '../../firebase.js';

import {UserContext} from '../../contexts/User';

import MemberHours from '../../components/members/Hours';

const Detail = function({row}) {
    const {uid, members, raw, hours: salary, hoursOverrides: rawSalaryOverrides = {}, ...rest} = row;
    const theme = useTheme();
    const [saving, setSaving] = useState(false);
    const [hasSalaryOverrideChanges, setHasSalaryOverrideChanges] = useState(false);
    const [salaryOverrides, setSalaryOverrides] = useState(rawSalaryOverrides || {});
    const {enqueueSnackbar} = useSnackbar();
    const db = getFirestore(firebaseApp);

    const sortedMembers = sortBy(members, ['station', 'lastName']);

    const {currentUser} = useContext(UserContext);

    const {isAdmin = false} = currentUser;

    const handleHourChange = useCallback((e, member) => {
        setHasSalaryOverrideChanges(true);

        setSalaryOverrides(prev => {
            const existingValue = prev[member.id];
            const rawValue = e.target.value;
            let newValue = rawValue === '' ? undefined : parseFloat(rawValue);

            if (isNil(newValue)) {
                const newData = {...prev};
                delete newData[member.id];
                return newData;
            }

            if (isNil(existingValue)) {
                newValue += parseFloat(salary);
            }
            
            return {
                ...prev,
                [member.id]: newValue
            };
        });
    }, [salary]);
    
    const handleSaveSalaryOverrides = useCallback(async () => {
        if (!hasSalaryOverrideChanges) {
            return;
        }

        try {
            setSaving(true);

            const ref = doc(db, 'training', uid);
            await updateDoc(ref, {
                'hoursOverrides': salaryOverrides
            });

            enqueueSnackbar('Saved training', {variant: 'success'});

            setHasSalaryOverrideChanges(false);
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setSaving(false);
    }, [db, hasSalaryOverrideChanges, salaryOverrides, uid, enqueueSnackbar]);
    
    const handleResetSalaryOverrides = useCallback(() => {
        setHasSalaryOverrideChanges(false);
        
        setSalaryOverrides(rawSalaryOverrides);
    }, [rawSalaryOverrides]);

    return (
        <Box sx={{p: 2, backgroundColor: theme.palette.grey[50], display: 'flex', flexDirection: 'column'}}>
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
                <Box sx={{flex: 1}}>
                    <MemberHours
                        members={sortedMembers}
                        defaultSalary={salary}
                        salaryOverrides={salaryOverrides}
                        handleHourChange={handleHourChange}
                    />
                </Box>
            </Box>

            {hasSalaryOverrideChanges && (
                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', mt: 1, mb: 1}}>
                    <Button disabled={saving} variant="outlined" size="small" sx={{mr: 1}} onClick={handleResetSalaryOverrides}>Reset Hour Changes</Button>
                    <LoadingButton loading={saving} variant="contained" size="small" onClick={handleSaveSalaryOverrides}>Save Hour Changes</LoadingButton>
                </Box>
            )}
                                            
            {isAdmin && (
                <TableContainer component={Paper} sx={{mt: 1}}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <CodeIcon sx={{fontSize: 20}} />
                                    <Box sx={{ml: 1}}>Raw Data</Box>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <ReactJson collapsed src={{iar: raw, ...rest}} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
};

export default Detail;