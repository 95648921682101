import React, {useState} from 'react';
import {Button, Grid, Alert, Typography, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {useForm, FormProvider} from 'react-hook-form';
import {LoadingButton} from '@mui/lab';
import {getFirestore, collection, query, orderBy} from 'firebase/firestore';
import {useSnackbar} from 'notistack';
import {getFunctions, httpsCallable} from 'firebase/functions';

import firebaseApp from '../firebase';

import CollectionAutocomplete from '../form/CollectionAutocomplete';

const VSSync = props => {
    const {open, onClose, training = {}} = props;
    const {uid, syncedToVS = false, raw} = training;
    const {type} = raw || {};
    const [loading, setLoading] = useState(false);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const functions = getFunctions(firebaseApp);
    const vsSyncTrainingToActivity = httpsCallable(functions, 'vsSyncTrainingToActivity');

    const db = getFirestore(firebaseApp);
    const ref = collection(db, 'vsActivities');
    const q = query(ref, orderBy('title', 'asc'));

    const methods = useForm({
        mode: 'onChange'
    });
    const {handleSubmit, formState} = methods;
    const {isValid} = formState;

    const handleSync = async (activities) => {
        setLoading(true);

        try {
            await vsSyncTrainingToActivity({uid, activities});

            onClose({
                uid,
                syncedToVS: true
            });

            enqueueSnackbar('Synced', {variant: 'success'});
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setLoading(false);
    };

    const onSubmit = async data => {
        const {activities} = data;
        if (!activities.length) {
            return;
        }

        if (syncedToVS) {
            enqueueSnackbar('This has already been synced. Are you sure you want to do it again?', {
                variant: 'warning',
                action: key => {
                    return (
                        <>
                            <Button onClick={() => {
                                handleSync(activities);
                                closeSnackbar(key);
                            }}>
                                Sync
                            </Button>
                            <Button onClick={() => closeSnackbar(key)}>
                                Cancel
                            </Button>
                        </>
                    );
                }
            });

            return;
        }

        handleSync(activities);
    };

    return (
        <FormProvider {...methods}>
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth="lg"
                sx={{mt: 8}}
                fullWidth
                disableEscapeKeyDown={loading}
            >
                <DialogTitle sx={{display: 'flex', alignItems: 'stretch', flexDirection: 'column'}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            Vector Solutions Sync
                            <Typography variant="subtitle1">{type}</Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    {syncedToVS && (
                        <Alert severity="error" sx={{mb: 2}}>
                            This has already been synced to Vector Solutions
                        </Alert>
                    
                    )}
                    <Grid container spacing={2}>
                        <Grid item sx={{display: 'flex', flexDirection: 'column', flex: 1}}>
                            <CollectionAutocomplete
                                label="Activity"
                                name="activities"
                                rules={{required: 'Activity is required'}}
                                query={q}
                                sx={{flex: 1}}
                                getOptionLabel={option => option.title}
                                autoFocus
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} disabled={loading}>Cancel</Button>
                    <LoadingButton loading={loading} disabled={!isValid} onClick={handleSubmit(onSubmit)}>Sync</LoadingButton>
                </DialogActions>
            </Dialog>
        </FormProvider>
    );
}

export default VSSync;