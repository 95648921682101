import React from 'react';
import {Box} from '@mui/material';

const Container = props => {
    // https://docs.google.com/spreadsheets/d/<documentId>/edit?usp=sharing&amp;widget=true&amp;headers=false

    return (
        <Box style={{display: 'flex', padding: 5, borderRadius: 5, flexDirection: 'column', alignItems: 'stretch', flex: 1, backgroundColor: '#eeeeee'}}>
            <iframe
                style={{flex: 1, border: 0, borderRadius: 5}} 
                src="https://docs.google.com/spreadsheets/d/1ZEe1ff1HgaEf3MDIbQKFaWovStunJ9sL_3xPJNh7C0U/view?usp=sharing"
            />
        </Box>
    );
};

export default Container;