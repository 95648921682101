import React, {useState, useEffect, useContext, useCallback} from 'react';
import {useTheme} from '@mui/material/styles';
import {Button, Container, Box, Card, CardActionArea, CardMedia, CardContent, Typography, Grid, CircularProgress, useMediaQuery} from '@mui/material';
import {useNavigate, Link as RouterLink} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {chunk} from 'lodash';
import moment from 'moment';
import {getFirestore, collection, getDocs, query, orderBy, where, limit} from 'firebase/firestore';

import {UserContext} from '../contexts/User';
import firebaseApp from '../firebase';

const ApparatusCard = props => {
    const {apparatus} = props;
    const navigate = useNavigate();

    const {uid, tag, imageUrl, latestCheck} = apparatus;

    const onClick = useCallback(e => {
        const {metaKey} = e;

        const url = `/${uid}`;
        if (metaKey) {
            window.open(url);
            return;
        }

        navigate(url);
    }, [navigate, uid]);

    return (
        <Card>
            <CardActionArea onClick={onClick}>
                <CardMedia
                    component="img"
                    height={240}
                    image={imageUrl}
                    alt={tag}
                />
                <CardContent>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Typography variant="h5" component="div" sx={{flex: 1}}>
                            {tag}
                        </Typography>
                        <Typography variant="overline" component="div">
                            Last Check: {moment(latestCheck).fromNow()}
                        </Typography>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

const Apparatuses = () => {
    const [loading, setLoading] = useState(true);
    const [docs, setDocs] = useState([]);
    const db = getFirestore(firebaseApp);
    const {enqueueSnackbar} = useSnackbar();
    const {currentUser, member} = useContext(UserContext);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const {isAdmin = false} = currentUser;
    const {station} = member ? member : currentUser;

    useEffect(() => {
        let isSubscribed = true;

        async function fetch() {
            try {
                let q = collection(db, 'apparatus');
                if (!isAdmin) {
                    q = query(q, where('station', '==', station));
                } else {
                    q = query(q, orderBy('station'));
                }

                const raw = await getDocs(q);
                let docs = [];

                raw.forEach(doc => {
                    const {type, ...rest} = doc.data();
                    
                    if (type === 'CAR') {
                        return;
                    }

                    docs.push({
                        id: doc.id,
                        uid: doc.id,
                        ...rest
                    });
                });

                docs = await Promise.all(docs.map(async apparatus => {
                    const {id} = apparatus;
                    
                    const ref = collection(db, 'apparatus', id, 'checks');
                    const q = query(ref, where('type', '==', 'weekly'), orderBy('createdAt', 'desc'), limit(1));

                    const raw = await getDocs(q);
                    let check;

                    raw.forEach(doc => {
                        check = doc.data();
                    });

                    return {
                        ...apparatus,
                        latestCheck: check.createdAt.toDate() 
                    };
                }));

                if (isSubscribed) {
                    setDocs(docs);
                }
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }

            if (isSubscribed) {
                setLoading(false);
            }
        }

        fetch();
        
        return () => isSubscribed = false;
    }, [enqueueSnackbar, db, station, isAdmin]);

    const chunked = chunk(docs || [], isSmall ? 1 : 2);

    return (
        <Container maxWidth="md">
            <Box style={{display: 'flex', height: '100%'}}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <Grid container spacing={2}>
                        {chunked.map(docs => {
                            return docs.map(doc => (
                                <Grid item xs={12} sm={6} key={doc.uid}>
                                    <ApparatusCard apparatus={doc} key={doc.uid} />
                                </Grid>
                            ));
                        })}
                    </Grid>
                )}
            </Box>

            {isAdmin && (
                <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                    <Button component={RouterLink} variant="contained" to="/new">Add Apparatus</Button>
                </Box>
            )}
        </Container>
    );
};

export default Apparatuses;